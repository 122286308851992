// Functionality of this code : This is the landing page of the application
// Input : None
// Output : Landing Page
// Line of Code: 214
// Written by: Initial Team VK, KR

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Footer from './components/Footer';
import logo from './assets/newlogo.png';
import HomePage from './HomePage';
import ContactPage from './ContactPage';
import Header from './components/Header/Header';
import AuthPage from './AuthPage';
import AboutPage from './AboutPage';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import DashBoardPage from './DashBoardPage';
import HowItWorksPage from './HowItWorksPage';
import FAQ from './FAQ';
import StudentPage from './StudentPage';
import UniversityPage from './UniversityPage';
import DonorPage from './DonorPage';
import NewProfilePage from './NewProfilePage';
import SettingsPopOut from './components/SettingsPopOut';
import SessionTimeout from './SessionTimeout';
import { useNavigate } from 'react-router-dom';
// import './App.css';
import AdminLogin from './pages/admin/adminLogin';
import Adminapp from './pages/admin/Adminapp';
import UniquegGift from './components/UniqueGift';
import Invoice from './components/Invoice';
import Success from './components/Success';
import SponsorVoucher from './components/SponsorVoucher';
import StudentVoucher from './components/StudentVoucher';
import OnlineVoucher from './components/OnlineVoucher';
import secureLocalStorage from 'react-secure-storage';
import toast, { Toaster } from 'react-hot-toast';
import { messaging, app as firebase } from './configs/firebase';
import AddStudentPage from './pages/organization/AddStudentPage';

require('dotenv').config();

// Define the constant page style
// const useStyles = makeStyles(theme => ({
//   '@global': {
//     ul: {
//       margin: 0,
//       padding: 0,
//       listStyle: 'none',
//     },
//   },

//   root: {
//     flexGrow: 1,
//   },

//   appBar: {
//     borderBottom: `1px solid ${theme.palette.divider}`,
//   },

//   toolbar: {
//     flexWrap: 'wrap',
//     minHeight: 99,
//     paddingRight: 74,
//   },

//   toolbarTitle: {
//     marginLeft: 20,
//     flexGrow: 1,
//   },

//   link: {
//     margin: theme.spacing(1, 1.5),
//   },

//   logoHead: {
//     top: 0,
//     width: 126,
//     height: 126,
//     position: 'absolute',
//     alignItems: 'center',
//     userSelect: 'none',
//     left: '3%',
//   },

//   headButton: {
//     color: '#ed0a3f',
//     '&:hover': {
//       backgroundColor: '#fff !important',
//     },
//   },

//   headLoginButton: {
//     backgroundColor: '#232323',
//     color: '#f2f2f2',
//     '&:hover': {
//       background: '#232323',
//     },
//   },
// }));

// Applicaton configuration and initialization using Firebase

// Main APP starts here
function App() {
  // initiate state for page need to nav.
  const [page, setPage] = React.useState('');
  const [user, setUser] = React.useState('');
  const [data, setData] = React.useState('');
  // initiate state for update profile popup
  const [newOpen, setNewOpen] = React.useState(false);
  // initiate state for contact us popup
  const [openContact, setContact] = React.useState(false);
  // initiate state for user role
  const [role, setRole] = React.useState(secureLocalStorage.getItem('Role'));
  // initiate state for user auth active or inactive
  const [isAuthenticated, setIsAuth] = React.useState(false);
  // logged User mail
  const [userEmail, SetUserEmail] = useState('');

  const navigate = useNavigate();
  // click event for sign out by firebase
  const handleClick = () => {
    setPage('home');
    navigate('/');
    // setRole('');
    toast.success('Signed out', { autoClose: 2000 });
    firebase.auth().signOut();
  };
  let params = {};
  let elemetUrl = window.location.href.split('/');
  const urlParams = window.location.search;
  const urlParams1 = urlParams.slice(1).split('&');

  urlParams1.forEach(elm => {
    if (elm === '') return;
    let spl = elm.split('=');
    const d = decodeURIComponent;
    params[d(spl[0])] = spl.length >= 2 ? d(spl[1]) : true;
  });

  async function requestPermission() {
    Notification.requestPermission()
      .then(() => {
        console.log('Notification permission granted.');
        return messaging.getToken();
      })
      .then(token => {
        console.log('Firebase messaging token:', token);
        // Handle your token (send to server, etc.)
      })
      .catch(error => {
        console.error(
          'Error occurred while getting Firebase messaging token:',
          error
        );
      });
  }

  // useEffect perform data fetching or call some other imperative API
  useEffect(() => {
    // get the current user is by setting an observer on the Auth object
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        if (user.email === 'admin@sammantrana.com') {
          firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then(tokenResult => {
              const getRequestOptions = {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  auth: tokenResult.token,
                },
              };
              if (tokenResult.claims.email === 'admin@sammantrana.com') {
                setRole('admin');
                setPage('Dashboard');
                setIsAuth(true);
              }
            });
        } else {
          //for other users
          firebase
            .auth()
            .currentUser.getIdTokenResult(true)
            .then(async tokenResult => {
              setUser(tokenResult);
              const getRequestOptions = {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  auth: tokenResult.token,
                },
              };
              if (
                tokenResult.claims.role !== undefined &&
                tokenResult.claims.email !== 'admin@sammantrana.com'
              ) {
                setRole(tokenResult.claims.role);
                SetUserEmail(tokenResult.claims.email);
                await fetch(
                  process.env.REACT_APP_PROXY + '/user/pref',
                  getRequestOptions
                )
                  .then(res => res.json())
                  .then(data => {
                    //data api not success that mean new user try to login
                    if (data.success === false) {
                      setPage('newprofile');
                      // requestPermission();
                    } else {
                      // console.log('Existing user');
                      //data api success that mean existing user
                      setPage('dashboard');
                      // requestPermission();
                    }
                  })
                  .catch(err => {
                    throw new Error(
                      'An error occurred while fetching data',
                      err
                    );
                  });
              }
              //set state for role
              setIsAuth(true);
              if (params && params.status == 'completed') {
                params = {};
                setPage('success');
              } else {
                setPage('dashboard');
              }
              await fetch(
                process.env.REACT_APP_PROXY + '/user/pref',
                getRequestOptions
              )
                .then(res => res.json())
                .then(data => {
                  setIsAuth(true);
                  //data api not success that mean new user try to login
                  if (data.success === false) {
                    setPage('newprofile');
                  } else {
                    //data api success that mean existing user
                    setPage('dashboard');
                  }
                })
                .catch(err => {
                  throw new Error('An error occurred while fetching data', err);
                });
            });
        }
        // User is signed in, see docs for a list of available properties

        // set state for user is authorized
      } else {
        // set state for user is unauthorized
        setIsAuth(false);
        // if (url === 'http://localhost:3000/admin') {
        //   setPage('login');
        //   <Login/>
        // }
        if (elemetUrl[3] == 'admin') {
          setPage('admin');
        } else {
          setPage('home');
          // navigate('/')
        }
      }
    });
    return () => unsubscribe();
  }, []);

  // update code
  // useEffect(() => {
  //   const handleUserAuthentication = async user => {
  //     if (!user) {
  //       setIsAuth(false);
  //       setPage(elemetUrl[3] === 'admin' ? 'admin' : 'home');
  //       return;
  //     }

  //     try {
  //       const tokenResult = await firebase
  //         .auth()
  //         .currentUser.getIdTokenResult();
  //       const isAdmin = user.email === 'admin@sammantrana.com';

  //       if (isAdmin || tokenResult.claims.role) {
  //         setRole(isAdmin ? 'admin' : tokenResult.claims.role);
  //         setIsAuth(true);

  //         if (isAdmin) {
  //           setPage('Dashboard');
  //         } else {
  //           const getRequestOptions = {
  //             method: 'GET',
  //             headers: {
  //               'Content-Type': 'application/json',
  //               auth: tokenResult.token,
  //             },
  //           };

  //           const res = await fetch(
  //             `${process.env.REACT_APP_PROXY}/user/pref`,
  //             getRequestOptions
  //           );
  //           const data = await res.json();

  //           if (params && params.status === 'completed') {
  //             params = {};
  //             setPage('success');
  //           } else if (data.success === false) {
  //             setPage('newprofile');
  //           } else {
  //             setPage('dashboard');
  //           }
  //         }
  //       } else {
  //         setIsAuth(false);
  //         setPage('home');
  //       }
  //     } catch (err) {
  //       console.error(err);
  //       setIsAuth(false);
  //     }
  //   };

  //   const unsubscribe = firebase
  //     .auth()
  //     .onAuthStateChanged(handleUserAuthentication);
  //   return () => unsubscribe();
  // }, []);

  return (
    // This is what we see on the screen logOut={handleClick}
    // All the buttons on the header and the related functionality is called from here
    <>
      <div className="App" >
        {/* Top header */}
        <Header
          isAuthenticated={isAuthenticated}
          logOut={handleClick}
          logoSrc={logo}
          setPage={setPage}
          firebase={firebase}
          setNewOpen={setNewOpen}
          setContact={setContact}
          setRole={setRole}
          page={page}
          role={role}
        />
        {/* session timeout  */}
        <SessionTimeout
          isAuthenticated={isAuthenticated}
          logOut={handleClick}
        />
        {/*home page navigetion */}
        {page === 'home' ? <HomePage setPage={setPage} /> : ' '}
        {/* Contact us page popup*/}
        <ContactPage open={openContact} setContact={setContact} />
        {/* Terms page navigation*/}
        {page === 'termsofservice' ? <TermsOfService /> : ' '}
        {/* Privacy page navigation*/}
        {page === 'privacypolicy' ? <PrivacyPolicy /> : ' '}
        {/* About page navigation*/}
        {page === 'about' ? <AboutPage setPage={setPage} /> : ' '}
        {/* how it works page navigation*/}
        {page === 'howitworks' ? <HowItWorksPage setPage={setPage} /> : ' '}
        {/* FAQ page */}
        {page === 'faq' ? <FAQ /> : ' '}
        {/* Auth page navigation*/}
        {page === 'auth' ? (
          <AuthPage setPage={setPage} role={role} setRole={setRole} />
        ) : (
          ''
        )}
        {/* profile popup */}
        {page === 'newprofile' ? (
          <NewProfilePage role={role} setPage={setPage} />
        ) : (
          ''
        )}
        {page === 'uniqegift'
          ? role === 'sponsor' && (
              <UniquegGift setPage={setPage} setData={setData} data={data} />
            )
          : ''}
        {page === 'uniqegiftOnline'
          ? role === 'sponsor' && (
              <OnlineVoucher setPage={setPage} setData={setData} />
            )
          : ''}
        {page === 'success'
          ? role === 'sponsor' && <Success setPage={setPage} />
          : ''}
        {page === 'invoice'
          ? role === 'sponsor' && (
              <Invoice data={data} user={user} setPage={setPage} />
            )
          : ''}
        {/* Donor page navigation */}
        {page === 'page'
          ? role === 'sponsor' && (
              <DonorPage setData={setData} setPage={setPage} />
            )
          : ''}
        {page === 'vouchers'
          ? role === 'sponsor' && (
              <SponsorVoucher setData={setData} setPage={setPage} />
            )
          : ''}
        {page === 'stuVoucher'
          ? role === 'student' && (
              <StudentVoucher setData={setData} setPage={setPage} />
            )
          : ''}
        {/* Student page navigation*/}
        {page === 'page' ? role === 'student' && <StudentPage /> : ''}
        {/* university page navigation */}
        {/* {page === 'page' ? role === 'organization' && <UniversityPage /> : ''} */}
        {page === 'page' ? role === 'organization' && <AddStudentPage/> : ''}
        {/* dashboard page navigation */}
        {page === 'dashboard' ? (
          <DashBoardPage setPage={setPage} role={role} />
        ) : (
          ''
        )}
        {/* update profile popup */}
        <SettingsPopOut
          open={newOpen}
          setOpen={setNewOpen}
          role={role}
          email={userEmail}
        />{' '}
        {/* footer */}
        {/* <Link to={'/admin'}> </Link>
        <Routes>
          <Route exact path="/admin" element={<Login />}></Route>
        </Routes> */}
        {page === 'admin' ? <AdminLogin setPage={setPage} /> : ''}
        {page === 'Dashboard' ? role === 'admin' && <Adminapp /> : ''}
        {/* <Routes>
            <Route exact path="/Dashboard" element={<DashBoard/>}>
            </Route>
          </Routes> */}
        {role == 'admin' ? (
          ''
        ) : (
          <Footer
            setPage={setPage}
            setContact={setContact}
            logOut={handleClick}
            isAuthenticated={isAuthenticated}
          />
        )}
      </div>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 2000,
          style: {
            fontSize: '20px',
            fontWeight: 'bolder',
            width: 'fit-content',
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }}
      />
    </>
  );
}

export default App;
