import React from 'react';
import { CardsTooltip } from '../utils/MyTooltip';
import donate from '../assets/donatenow.gif';
import { useMediaQuery, useTheme } from '@mui/material';

const Cards = props => {
   const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {CardsTooltip(
        props.message,
        props.position,
        <div
          className={`main_card`}
          onClick={props.onClick}
          style={{ position: 'relative' }}
        >
          <img
            src={
              props.number === 'Donate Now' && props.newRequest
                ? donate
                : props.img
            }
            alt="imgIcon"
            width={60}
          />
          <div className='detailCard' style={{textAlign:'center'}}>
            <span style={{fontSize:'1.3rem', fontFamily: 'fantasy'}}>
              {props.number}
            </span>
            <p>{props.title}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Cards;
