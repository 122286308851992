import '../css/studentRequest.css';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../css/style.css';
import dashImg1 from '../assets/dash-img-01.png';
import dashImg2 from '../assets/dash-img-02.png';
import dashImg3 from '../assets/dash-img-03.png';
import dashImg4 from '../assets/dash-img-04.png';
import TablePagination from '@mui/material/TablePagination';
import Cards from './Cards';
import Avatar from '@material-ui/core/Avatar';
import school from '../assets/icon1.png';
import books from '../assets/icon2.png';
import glasses from '../assets/icon3.png';
import student from '../assets/user.png';
import details from '../assets/details.png';
import ActiveReq from '../assets/activeReq.png';
import FullfilledReq from '../assets/fullfilledReq.png';
import PledgedReq from '../assets/pledgedReq.png';
import SupportedReq from '../assets/SupportedReq.png';
import Table from './Table';
import { formatDate } from '../utils/dateUtils';
import StudentRequestTable from '../components/StudentStatusTable';
import { setActiveTab } from '../store/actions/tabaction';
import { useDispatch } from 'react-redux';
import ProgressTimeLine from './ProgressTimeline';
import { getInitials } from '../utils/GetNameInitials';
import '../css/button.css';
// Org Imports
import OrgChart from '../pages/organization/OrgChart';
import orgApplication from '../assets/orgAssets/orgApplication.png';
import orgDonations from '../assets/orgAssets/orgDonations.png';
import OrgApplicationMatched from '../assets/orgAssets/OrgApplicationMatched.png';
import orgSponsor from '../assets/orgAssets/orgSponsor.png';
import Loading from './Loader/Loader';
import Spinner from '../utils/spinner';

// Styling with Material-UI
const useStyles = makeStyles(theme => ({
  // your styles here
}));

// Function to get card data based on role
const getCardData = (role, data) => {
  const {
    balance,
    RequestSponsored,
    RequestReceived,
    RequestFullfill,
    RequestPledged,
    RequestActiveStudent,
    TotalReqMade,
    VolunteerHours,
    ProjectHours,
    CategoryBalances,
    totalDonatedAmount,
    dashboardDetails,
  } = data;

  if (role === 'sponsor') {
    return [
      {
        img: books,
        number: `$ ${CategoryBalances.Books || 0}`,
        title: 'Books Balance',
        tooltipmessage: 'The total Balance for Books',
        position: 'top',
      },
      {
        img: school,
        number: `$ ${CategoryBalances.Tuition || 0}`,
        title: 'Tuition Balance',
        tooltipmessage: 'The total Balance for Tuition',
        position: 'top',
      },
      {
        img: glasses,
        number: `$ ${CategoryBalances['Pair Of Glasses'] || 0}`,
        title: 'Glasses Balance',
        tooltipmessage: 'The total Balance for Pair of Glasses',
        position: 'top',
      },
      {
        img: dashImg4,
        number: `$ ${balance}`,
        title: 'Total Balance',
        tooltipmessage:
          'Overall Total Balance including Books,Tuition and Glasses',
        position: 'top',
      },
      {
        img: dashImg1,
        number: RequestReceived,
        title: 'Matched Requests',
        tooltipmessage:
          'The total number of requests or matches you received from a student.',
        position: 'bottom',
      },
      {
        img: dashImg2,
        number: RequestSponsored,
        title: 'Requests Sponsored',
        tooltipmessage: 'The total number of requests you sponsored till now',
        position: 'bottom',
      },
      {
        img: dashImg3,
        number: `$ ${totalDonatedAmount}`,
        title: 'Total Donation',
        tooltipmessage: 'The total number of amount you donated till now',
        position: 'bottom',
      },
      {
        img: details,
        number: 'Donate',
        title: 'Click here to donate',
        tooltipmessage: 'Click above to redirect to Donate page',
        position: 'bottom',
      },
    ];
  } else if (role === 'student') {
    return [
      {
        img: ActiveReq,
        number: RequestActiveStudent,
        title: 'Active Requests',
        tooltipmessage:
          'The total number of active applications you submitted that are still awaiting sponsorship.',
        position: 'top',
      },
      {
        img: FullfilledReq,
        number: RequestFullfill,
        title: 'Requests fullfilled',
        tooltipmessage: 'The total number of sponsorship you got till now',
        position: 'top',
      },
      {
        img: PledgedReq,
        number: RequestPledged,
        title: 'Request pledged',
        tooltipmessage:
          'Total hours you will contribute after academic completion.',
        position: 'top',
      },
      {
        img: SupportedReq,
        number: RequestFullfill,
        title: 'Supported requests',
        tooltipmessage:
          'Total number of students you can support in the future',
        position: 'top',
      },
    ];
  } else if (role === 'organization') {
    return [
      {
        img: orgApplication,
        number: dashboardDetails.TotalApplication,
        title: 'Total Applications',
        tooltipmessage:
          'The total number of Applications Request raised by students.',
        position: 'top',
      },
      {
        img: OrgApplicationMatched,
        number: dashboardDetails.MatchedApplications,
        title: 'Matched Applications',
        tooltipmessage:
          'The total number of active applications submitted by your students that have been matched.',
        position: 'top',
      },
      {
        img: orgSponsor,
        number: dashboardDetails.ConnectedSponsors,
        title: `Connected Sponsors`,
        tooltipmessage:
          'The total number of sponsors connected to your organization.',
        position: 'top',
      },
      {
        img: orgDonations,
        number: dashboardDetails.TotalDonation,
        title: 'Overall Donations',
        tooltipmessage:
          'The total amount of donations received by your organization.',
        position: 'top',
      },
    ];
  }
  {
    return [
      {
        img: dashImg1,
        number: TotalReqMade,
        title: 'Requests made',
      },
      {
        img: dashImg1,
        number: RequestFullfill,
        title: 'Requests fullfilled',
      },
      {
        img: dashImg1,
        number: VolunteerHours,
        title: 'Hours pledged',
      },
      {
        img: dashImg1,
        number: ProjectHours,
        title: 'Hours volunteered',
      },
      {
        img: dashImg1,
        number: VolunteerHours,
        title: 'Sponsorship Request pledged',
      },
      {
        img: dashImg1,
        number: ProjectHours,
        title: 'Sponsorship Supported requests',
      },
    ];
  }
};

export default function CommonDashboard(props) {
  const classes = useStyles();

  const {
    balance,
    donationData,
    RequestSponsored,
    RequestFullfill,
    RequestPledged,
    RequestActiveStudent,
    TotalReqMade,
    VolunteerHours,
    ProjectHours,
    RequestReceived,
    CategoryBalances,
    loading,
    role,
    setpage,
    dashboardDetails,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on page size change
  };

  // Calculate the sum of donated amounts
  const totalDonatedAmount = donationData.reduce((acc, donation) => {
    return acc + (donation.donateamount || 0); // Default to 0 if donateamount is undefined
  }, 0);

  // Calculate amount for specific need_type

  const rows = donationData;

  // Prepare data for card rendering
  const data = {
    balance,
    donationData,
    RequestSponsored,
    RequestReceived,
    RequestFullfill,
    RequestPledged,
    RequestActiveStudent,
    TotalReqMade,
    VolunteerHours,
    ProjectHours,
    CategoryBalances,
    totalDonatedAmount,
    dashboardDetails,
  };
  const Carddata = getCardData(role, data);

  const columns = [
    'User Image',
    'Name',
    'Application No.',
    'Donated Amount',
    'Need Type',
    'Request Raised On',
    'Need Date',
    'Fulfilled On',
  ];

  //Render rows
  const renderRow = rowData => (
    <>
      <td>
        {/* <Avatar alt={rowData.name} src={student || ''} /> */}
        <Avatar
          alt="Profile Picture"
          style={{
            backgroundColor: '#2998D1',
            color: '#fff',
            width: 40,
            height: 40,
            fontSize: '1.2rem',
            marginBottom: '5px',
          }}
        >
          {getInitials(rowData.name)}
        </Avatar>
      </td>
      <td>{rowData.name}</td>
      <td>{rowData.applicationnumber}</td>
      <td>{rowData.donateamount}</td>
      <td>
        <img
          src={getNeedTypeClass(rowData.needtype)}
          width="40%"
          alt={rowData.needtype}
        />
        <p style={{ fontSize: '13px' }}>{rowData.needtype}</p>
      </td>
      <td>{formatDate(rowData.requestdate)}</td>
      <td>{formatDate(rowData.needdateline)}</td>
      <td>{formatDate(rowData.fulfilledon)}</td>
    </>
  );

  // Function to get the appropriate class based on needtype
  const getNeedTypeClass = needtype => {
    switch (needtype) {
      case 'Books':
        return books;
      case 'Tuition':
        return school;
      case 'Pair Of Glasses':
        return glasses;
      default:
        return '';
    }
  };

  return (
    <>
      {loading ? (
    props.role === 'organization' ? (
          <Loading.orgLoader />
        ) : <Spinner />
      ) : (
        <section className="dashboard_main_section banner-image ">
          <div className="submit-section container">
            <div className="row">
              {Carddata.map((item, index) => (
                <div
                  className="col-lg-3 col-md-6 col-sm-12 mb-4 col-6"
                  key={`${item.title}-${index}`}
                >
                  <Cards
                    id={`card-${index}`}
                    img={item.img}
                    title={item.title}
                    number={item.number}
                    message={item.tooltipmessage}
                    position={item.position}
                    onClick={
                      item.number === 'Donate'
                        ? () => {
                            setpage('page');
                            dispatch(setActiveTab('page'));
                          }
                        : undefined
                    }
                    newRequest={RequestReceived}
                  />
                </div>
              ))}
            </div>

            {role === 'student' ? (
              <>
                <ProgressTimeLine />
                <StudentRequestTable />
              </>
            ) : role === 'sponsor' ? (
              <section className="sponsorship-requests-section">
                <div className="container">
                  <div className="mb-2">
                    <b className="fs-4 ">Donation Data</b>
                  </div>

                  <div className="shadow-box">
                    <div className="table-bottom table-sec">
                      <Table
                        columns={columns}
                        rows={rows}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        renderRow={renderRow}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </section>
            ) : role === 'organization' ? (
              <OrgChart apiData={dashboardDetails}/>
            ) : (
              ''
            )}
          </div>
        </section>
      )}
    </>
  );
}
