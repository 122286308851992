// Functionality of this code : This page is for setting up new profiles
// Input : None
// Output :
// Written by: Initial team VK, KR

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Preferences } from './components/StudentDonorProfile';
import { OrgProfile } from './pages/organization/orgProfile';

// this is the settings when user just signed up
const useStyles = makeStyles(theme => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: 'white',
    marginBottom: theme.spacing(4),
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.0)',
  },

  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
      paddingRight: 0,
    },
  },

  cardDetails: {
    flex: 1,
  },

  cardMedia: {
    width: 800,
  },
}));

const isProfileComplete = (role, profileData) => {
  if (role === 'sponsor') {
    return (
      profileData.name.length > 0 &&
      profileData.country.title.length > 0 &&
      profileData.need_type.length > 0 &&
      profileData.donateMax.length > 0 &&
      profileData.times_i_can_donate.length > 0 &&
      profileData.university.length > 0 &&
      profileData.field.title.length > 0
    );
  } else if (role === 'student') {
    return (
      profileData.name.length > 0 &&
      profileData.country.title.length > 0 &&
      profileData.state.states.length > 0 &&
      profileData.mystate.title.length > 0 &&
      profileData.start_year.title.length > 0 &&
      profileData.end_year.title.length > 0 &&
      profileData.field.title.length > 0 &&
      profileData.student_id.length > 0 &&
      profileData.university.length > 0
    );
  }
  return false;
};

//Encapsulate the entire preferences/profile page into a card
//open new user profile for filling the records
export default function SettingsCard(props) {
  const classes = useStyles();
  const role = props.role;

  return (
    <>
      <CssBaseline />
      <div className="bannner-image">
        <Container>
          <br />
          <br />
          <br />
          <Grid item xs={12} md={12}>
            <Card className={classes.card}>
              <div style={{ paddingLeft: 20, paddingRight: 10, border: '1px solid gray', borderRadius:'5px'  }}>
                {role === 'student' || role === 'sponsor' ? (
                  <>
                    <Preferences
                      setPage={props.setPage}
                      role={props.role}
                      email={props.email}
                    />
                  </>
                ) : (
                  <OrgProfile setPage={props.setPage} />
                )}
              </div>
            </Card>
          </Grid>
          <br />
          <br />
        </Container>
      </div>
    </>
  );
}
