export const getRoleStyles = role => {
  switch (role) {
    case 'admin':
      return {
        color: 'white',
        backgroundColor: '#007BFF', 
      };
    case 'student':
      return {
        color: 'black',
        backgroundColor: 'rgb(67, 168, 219)', 
      };
    case 'sponsor':
      return {
        color: 'white',
        backgroundColor: 'rgb(207, 57, 86)', 
      };
    case 'organization':
      return {
        color: 'white',
        backgroundColor: 'rgb(69,69,69)',
      };
    default:
      return {
        color: 'black',
        backgroundColor: '#FFFFFF',
      };
  }
};
