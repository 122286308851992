import { legacy_createStore as createStore, combineReducers } from 'redux';
import userReducer from './reducers/userReducers';
import tabReducer from './reducers/tabReducer';
import eligibilityReducer from './reducers/eligibilityReducer';
import notificationReducer from './reducers/notificationReducer';
import orgDetailsReducer from './reducers/orgDetailsReducer';

const rootReducer = combineReducers({
  user: userReducer,
  tab: tabReducer,
  eligibility: eligibilityReducer,
  notification: notificationReducer,
  organizationDetails : orgDetailsReducer
});

const store = createStore(rootReducer);

export default store;
