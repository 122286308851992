import React from 'react';
import './message.css';

const NotificationMessage = props => {
  const { selectedNotification, handleClose, getIconClass } = props;

  // Function to get appropriate emojis based on the title
  // const getEmojis = title => {
  //   console.log('title -> ', title);
  //   switch (title) {
  //     case 'You’ve Received a Donation!':
  //       return '💸 🎁';
  //     case 'Thank You for Your Donation!':
  //       return '❤️ 🙏';
  //     case 'Application Matched':
  //       return '✅ 🎉';
  //     case 'New Application Match Found':
  //       return '🔍 🎯';
  //     default:
  //       return '❤️ 🙏';
  //   }
  // };

  return (
    <>
      <div className="NotificationMessageCard">
        <div className="NotificationMessage_header">
          <span className="NotificationMessage_icon">
            <i
              className={`icon ${getIconClass(selectedNotification.title)}`}
            ></i>
          </span>
          <p className="NotificationMessage_alert">
            {selectedNotification.title}
          </p>
        </div>
        {selectedNotification.title === 'Message from a Student' || selectedNotification.title === 'To sponsor' ? (
          <p
            className="notification-reading"
            dangerouslySetInnerHTML={{
              __html: selectedNotification.message,
            }}
          />
        ) : (
          <p className="NotificationMessage_message">
            {selectedNotification.message}
          </p>
        )}
        <div className="NotificationMessage_actions">
          <a
            className="NotificationMessage_read"
            style={{ color: 'white' }}
            onClick={handleClose}
          >
            Close
          </a>
        </div>
      </div>
    </>
  );
};

export default NotificationMessage;
