import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BookIcon from '@material-ui/icons/Book';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import CommentIcon from '@material-ui/icons/Comment';
import StepConnector from '@material-ui/core/StepConnector';
import checkAuth from '../utils/checkAuth';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { timelineTooltip } from '../utils/MyTooltip';
import CommonModal from './CommonModal';
import SendMessage from './SendMessage';
import SchoolIcon from '@material-ui/icons/School';
import { MenuItem, Select, Tabs, Tab, Paper } from '@material-ui/core';
import { useMediaQuery, useTheme } from '@mui/material';
import Spinner from '../utils/spinner';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.12)',
    position: 'relative',
    overflow: 'hidden',
  },
  active: {
    backgroundImage:
      'linear-gradient(136deg, rgb(0,200,83) 0%, rgb(0,150,50) 50%, rgb(0,100,33) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient(136deg, rgb(0,200,83) 0%, rgb(0,150,50) 50%, rgb(0,100,33) 100%)',
  },
  shine: {
    position: 'absolute',
    top: 0,
    left: '-100%',
    width: '250%',
    height: '100%',
    backgroundImage:
      'linear-gradient( 120deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.6),rgba(255, 255, 255, 0) 70%)',
    transform: 'skewX(-45deg)',
    animation: `$shineEffect 2s linear infinite`,
  },
  '@keyframes shineEffect': {
    '0%': {
      left: '-100px',
    },
    '60%': {
      left: '100%',
    },
    to: {
      left: '100%',
    },
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, icon, needType } = props;

  const commonIcons = {
    2: <EmojiEventsIcon />,
    3: <CheckCircleIcon />,
    4: <CommentIcon />,
  };

  const specificIcons = {
    Books: <BookIcon />,
    Tuition: <SchoolIcon />,
    'Pair Of Glasses': <i className="fa-solid fa-glasses fs-5"></i>,
  };

  const getIcon = () => {
    if (icon === 1) {
      return specificIcons[needType];
    }
    return commonIcons[icon];
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {getIcon()}
      {active && <div className={classes.shine}></div>}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
  needType: PropTypes.string,
};

const ColorlibConnector = withStyles({
  alternativeLabel: { top: 22 },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient(136deg, rgb(0,200,83) 0%, rgb(0,150,50) 50%, rgb(0,100,33) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient(136deg, rgb(0,200,83) 0%, rgb(0,150,50) 50%, rgb(0,100,33) 100%)',
    },
  },
  line: { height: 3, border: 0, backgroundColor: '#eaeaf0', borderRadius: 1 },
})(StepConnector);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  stepperContainer: {
    width: '100%',
  },
  tabRoot: {
    background: '#af3636',
    borderRadius: '5px 5px 0px 0px',
    color: 'white',
  },
  indicator: {
    color: '#77000D',
    background: 'white',
    height: '4px',
    borderRadius: '2px',
  },
  select: {
    background: 'white',
  },
  stepper: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
}));

function getSteps(needType, isMobile) {
  return [
    `Your application for ${needType} has been submitted.`,
    `You’ve matched with a sponsor for ${needType}.`,
    `Your ${needType} application has been fulfilled.`,
    `${
      isMobile
        ? 'Write a personal note to your sponsor'
        : 'Thank you for your support. Please write a personal note to your sponsor.'
    }`,
  ];
}

export default function ProgressTimeline() {
  const classes = useStyles();
  const [activeSteps, setActiveSteps] = useState([]);
  const application = useSelector(state => state.user.application);
  const applicationDetails = application.filter(app => 
    app.thank_you_message_sent === null
  );
  const [donationDetails, SetdonationDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [donorId, setDonorId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendMail, SetsendMail] = useState(false);
  const [selectedApplication, set_selectedApplication] = useState('');
  const notificaiton = useSelector(state => state.notification.notification);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const checkMatchAndUpdateSteps = async (idToken, applications) => {
    const applicationNumbers = applications.map(app => app.application_no);
    if (!applicationNumbers.length) return;

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_PROXY
        }/user/activeReq?application_numbers=${applicationNumbers.join(',')}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
        }
      );
      const data = await response.json();
      if (data.message && data.profile) {
        SetdonationDetails(data.message);
        const newActiveSteps = applications.map(app => {
          const matchFound = data.message.find(
            donation => donation.application_no === app.application_no
          );

          let activeStep = 0;
          if (matchFound) activeStep = 1;
          if (app.status === 'Fullfilled') {
            activeStep = 2;
            if (app.thank_you_message_sent === true) {
              activeStep = 3;
              SetsendMail(true);
            }
          }
          return activeStep;
        });

        setActiveSteps(newActiveSteps);
      }
    } catch (error) {
      console.error('Error checking match', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { idToken } = await checkAuth();
      if (!idToken) {
        toast.error('Authentication failed');
        setLoading(false);
        return;
      }
      if (application.length > 0) {
          await checkMatchAndUpdateSteps(idToken, applicationDetails);
      }
      setLoading(false);
    };

    fetchData();
  }, [application, notificaiton.length]);

  const handleSendMessageToSponsor = async message => {
    setLoading(true);
    try {
      const { idToken } = await checkAuth();

      if (!idToken) {
        toast.error('Authentication failed');
        setLoading(false);
        return;
      }

      const { REACT_APP_PROXY } = process.env;

      const response = await fetch(
        `${REACT_APP_PROXY}/user/send-message/thank-you`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
          body: JSON.stringify({
            sponsor_id: donorId,
            message,
            needtype: selectedApplication.need_type,
            applicationNo: selectedApplication.application_no,
          }),
        }
      );

      if (!response.ok) {
        const errorMessage = `Failed to send message (Status: ${response.status})`;
        setLoading(false);
        toast.error(errorMessage);
        console.error(errorMessage);
        return;
      }

      toast.success('Message sent successfully!');
      setLoading(false);
    } catch (error) {
      toast.error('An error occurred while sending message');
      setLoading(false);
      console.error('SendMessage Error:', error);
    }
  };

  const openSendMail = (donorId, application) => {
    setDonorId(donorId);
    set_selectedApplication(application);
    handleOpen(true);
  };

  const timelineLabels = (messageKey, dynamicData = {}, activeSteps, index) => {
    const TimeLinetooltipMessage = {
      step1: `Your application for ${dynamicData.needType} has been submitted on ${dynamicData.requestDate}.`,
      step2: `${
        dynamicData.matchDate
          ? `You were matched with a sponsor on ${dynamicData.matchDate}`
          : 'You don’t have any matches yet'
      }`,
      step3: `Your ${dynamicData.needType} application ${
        dynamicData.fulfillDate
          ? `was fulfilled on ${dynamicData.fulfillDate}`
          : 'has not been fulfilled yet'
      } .`,
      step4: `${
        activeSteps[index] === 2
          ? 'Click here and Write a thank you note to your sponsor for their support.'
          : activeSteps[index] === 3
          ? 'Thank you for choosing Sponsor-a-student'
          : 'You can send a thank you note once the application is fulfilled.'
      }`,
    };
    const message = TimeLinetooltipMessage[messageKey];
    return message;
  };

  return (
    <>
      {loading && <Spinner />}
      {applicationDetails.length > 0 && (
        <>
          <Paper className={classes.tabsContainer}>
            {isMobile ? (
              <Select
                value={tabIndex}
                onChange={e => handleTabChange(e, e.target.value)}
                fullWidth
                className={{ root: classes.select }}
                style={{ textAlign: 'center', padding: '4px 0' }}
              >
                {applicationDetails.map((application, index) => (
                  <MenuItem key={application.application_no} value={index}>
                    Application for {application.need_type}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                classes={{
                  root: classes.tabRoot,
                  indicator: classes.indicator,
                }}
                centered
              >
                {applicationDetails.map((application, index) => (
                  <Tab
                    key={application.application_no}
                    label={`Application for ${application.need_type}`}
                  />
                ))}
              </Tabs>
            )}
          </Paper>

          <div className={classes.root}>
            {applicationDetails.map((application, index) => {
              const matchData = donationDetails.find(
                donation => donation.application_no === application.application_no
              );
              const dynamicData = {
                needType: application.need_type,
                requestDate: new Date(
                  application.request_date
                ).toLocaleDateString(),
                matchDate: matchData
                  ? new Date(matchData.date).toLocaleDateString()
                  : null,
                fulfillDate:
                  application.status === 'Fullfilled'
                    ? new Date(application.gift_date).toLocaleDateString()
                    : null,
              };

              return isMobile
                ? index === tabIndex && (
                    <Timeline align="left" key={application.application_no}>
                      {getSteps(application.need_type, isMobile).map(
                        (label, stepIndex) => (
                          <TimelineItem key={label}>
                            <TimelineSeparator>
                              <TimelineDot
                                onClick={() => {
                                  if (
                                    stepIndex >= 3 &&
                                    activeSteps[index] >= 2 &&
                                    !application.thank_you_message_sent
                                  ) {
                                    const donor = donationDetails.find(
                                      app =>
                                        app.application_no ===
                                        application.application_no
                                    );
                                    openSendMail(
                                      donor?.donor_id,
                                      application
                                    );
                                  }
                                }}
                                style={{
                                  backgroundColor:
                                    activeSteps[index] === stepIndex
                                      ? '#c2ff87'
                                      : 'transparent',
                                  border:
                                    activeSteps[index] === stepIndex
                                      ? '2px solid white'
                                      : 'none',
                                }}
                              >
                                <ColorlibStepIcon
                                  icon={stepIndex + 1}
                                  needType={application.need_type}
                                  active={activeSteps[index] === stepIndex}
                                  completed={activeSteps[index] > stepIndex}
                                />
                              </TimelineDot>
                              {stepIndex <
                                getSteps(application.need_type).length - 1 && (
                                  <TimelineConnector />
                                )}
                            </TimelineSeparator>
                            <TimelineContent style={{ flex: 10 }}>
                              <Paper elevation={3} className={classes.paper}>
                                <Typography style={{ padding: '5px 8px' }}>
                                  <span>
                                    {timelineLabels(
                                      `step${stepIndex + 1}`,
                                      dynamicData,
                                      activeSteps,
                                      index
                                    )}
                                  </span>
                                </Typography>
                              </Paper>
                            </TimelineContent>
                          </TimelineItem>
                        )
                      )}
                    </Timeline>
                  )
                : index === tabIndex && (
                    <div
                      key={application.application_no}
                      className={classes.stepperContainer}
                    >
                      <Stepper
                        alternativeLabel
                        activeStep={activeSteps[index] || 0}
                        connector={<ColorlibConnector />}
                        className={`shadow border rounded-lg ${classes.stepper}`}
                      >
                        {getSteps(application.need_type, isMobile).map(
                          (label, stepIndex) => (
                            <Step key={label}>
                              {timelineTooltip(
                                `step${stepIndex + 1}`,
                                dynamicData,
                                activeSteps,
                                index,
                                'top',
                                <StepLabel
                                  StepIconComponent={props => (
                                    <ColorlibStepIcon
                                      {...props}
                                      needType={application.need_type}
                                    />
                                  )}
                                  icon={stepIndex + 1}
                                  onClick={() => {
                                    if (
                                      stepIndex >= 3 &&
                                      activeSteps[index] >= 2 &&
                                      !application.thank_you_message_sent
                                    ) {
                                      const donor = donationDetails.find(
                                        app =>
                                          app.application_no ===
                                          application.application_no
                                      );
                                      openSendMail(
                                        donor?.donor_id,
                                        application
                                      );
                                    }
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {label}
                                </StepLabel>
                              )}
                            </Step>
                          )
                        )}
                      </Stepper>
                    </div>
                  );
            })}
          </div>

          {open && (
            <CommonModal open={open} handleClose={handleClose}>
              <SendMessage setOpen={setOpen} onSend={handleSendMessageToSponsor} />
            </CommonModal>
          )}
        </>
      )}
    </>
  );
}