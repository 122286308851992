// Functionality of this code : This is where the user to lead to fill preferences on first time logging in
// Input : None
// Output : Profile/Preference Settings
// Written by: Initial team VK, KR
import '../css/modal.css';
import React, { useRef } from 'react';
import {} from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Preferences } from './StudentDonorProfile';
import { makeStyles } from '@material-ui/core';
import { OrgProfile } from '../pages/organization/orgProfile';

/* this is the settings page pop out when profile/pref is clicked */
export default function Settings(props) {
  const childRef = useRef();
  const role = props.role;
 
  const handleClose = () => {
    props.setOpen(false);
  };

  const useStyles = makeStyles(() => ({
    profileButton: {
      backgroundColor: '#232323',
      color: '#f2f2f2',
      '&:hover': {
        background: '#232323',
      },
    },
  }));

  const { profileButton } = useStyles();
  return (
    <div>
      <Dialog
        fullWidth
        modal="false"
        open={props.open}
        onClose={handleClose}
        color="primary"
        PaperProps={{
          sx: {
            borderRadius: '16px',
            padding: '20px',
            boxShadow: '0px 4px 16px 0px #00000030',
            '@media (max-width: 394px)': {
              padding: '5px 5px',
            },
          },
        }}
      >
        <h6
          style={{
            fontSize: window.innerWidth < 600 ? '15px' : '18px',
            fontWeight: '500',
            lineHeight: '21.09px',
            fontFamily: 'Roboto',
            padding: window.innerWidth < 600 ? '20px' : '24px',
          }}
          
        >
          {role === 'student'
            ? 'Student Profile'
            : role === 'sponsor'
            ? 'Sponsor Preferences'
            : 'Preferences'}
        </h6>
        <DialogContent>
          <form noValidate autoComplete="off">
            {role !== 'organization' ? (
              <Preferences
                setOpen={props.setOpen}
                role={props.role}
                disabled
                open={props.open}
                ref={childRef}
                handleClose={handleClose}
                email={props.email}
              />
            ) : (
              <OrgProfile
                setOpen={props.setOpen}
                role={props.role}
                disabled
                handleClose={handleClose}
                ref={childRef}
                open={props.open}
              />
            )}
          </form>
        </DialogContent>

        <div class="form-group btn-listing pb-4 px-2">
          {/* <DialogActions>
          <FlatButton
            color="primary"
            onClick={() => {
              childRef.current.getAlert();
              props.setOpen(false);
            }}
            handleClose={handleClose}
            className={profileButton}
          >
            {' '}
            Update
          </FlatButton>
          <FlatButton
            className={profileButton}
            color="primary"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {' '}
            Cancel
          </FlatButton>
        </DialogActions> */}
          <button
            className="btn btn-border"
            onClick={() => {
              props.setOpen(false);
            }}
            handleClose={handleClose}
          >
            Cancel
          </button>

          <button
            className="btn btn-fill "
            onClick={() => {
              childRef.current.getAlert();
              props.setOpen(false);
            }}
            handleClose={handleClose}
          >
            Update
          </button>
        </div>
      </Dialog>
    </div>
  );
}
