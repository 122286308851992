import CryptoJS from 'crypto-js';

const SECRET_KEY = (process.env.ENCRYPTION_KEY || "12345678901234567890123456789012").padEnd(32, '0').slice(0, 32);

const decrypt = (encryptedText) => {
    try {
        if (!encryptedText) return "Invalid Code";
        
        const parts = encryptedText.split(':'); // Extract IV and encrypted text
        if (parts.length !== 2) return "Invalid Code";

        const iv = CryptoJS.enc.Hex.parse(parts[0]); 
        const encryptedData = CryptoJS.enc.Hex.parse(parts[1]); 

        const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: encryptedData }, 
            CryptoJS.enc.Utf8.parse(SECRET_KEY), 
            { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
        );
        return decrypted.toString(CryptoJS.enc.Utf8) || "Invalid Code";
    } catch (error) {
        console.error("Decryption Error:", error);
        return "Invalid Code";
    }
};

export default decrypt;
