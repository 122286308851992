
  // Function to return color based on status
  export const getStatusColor = status => {
    switch (status) {
      case 'Fullfilled':
        return 'green';
      case 'Inactive':
        return 'grey';
      case 'Active':
        return 'blue';
      default:
        return 'transparent';
    }
  };