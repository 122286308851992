import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../common/CommonModal';
import NotificationMessage from './NotificationMessages/NotificationMessage';

function Notifications(props) {
  const notifications = useSelector(state => state.notification.notification);
  const {
    setShowDropdown,
    role,
    markAllAsRead,
    markAsRead,
    newNotificationCount,
    setPage,
    setActiveTab,
  } = props;
  const [open, setopen] = useState(false);
  const [selectedNotification, SetselectedNotification] = useState([]);
  const [activeTab, setActiveTabState] = useState('all');

  const handleClose = () => setopen(false);

  const handleNotificationClose = () => {
    setShowDropdown(false);
  };

  const OpenNotificationDetails = details => {
    SetselectedNotification(details);
    setopen(true);
  };

  // Function to truncate message to a certain length
  const truncateMessage = (message, maxLength) => {
    if (message.length > maxLength) {
      return message.substring(0, maxLength) + '...';
    }
    return message;
  };

  const getIconClass = title => {
    switch (title.trim()) {
      case 'You’ve Received a Donation!':
        return 'fa fa-gift';
      case 'Thank You for Your Donation!':
        return 'fa fa-heart';
      case 'Application Matched':
        return 'fa fa-check-circle';
      case 'New Application Match Found':
        return 'fa fa-search';
      case 'To sponsor':
        return 'fa fa-paper-plane';
      default:
        return 'fa fa-user';
    }
  };

  const filteredNotifications = notifications.filter(notif => {
    if (activeTab === 'all') {
      return true; // Show all notifications
    } else if (activeTab === 'application') {
      // Show only specific notification types for "Application" tab
      return [
        'Application Matched',
        'New Application Match Found',
        'You’ve Received a Donation!',
        'Thank You for Your Donation!',
      ].includes(notif.title);
    } else if (activeTab === 'message') {
      // Show "To sponsor" and default notifications for "Messages" tab
      return (
        ['To sponsor'].includes(notif.title) ||
        getIconClass(notif.title) === 'fa fa-user'
      );
    }
    return false;
  });


  const dispatch = useDispatch();

  return (
    <>
      <div className="notification-panel">
        <div className="notification-header">
          <h3>Notification</h3>
          <div className="actions">
            {newNotificationCount > 1 && (
              <button className="mark-all-read mx-3" onClick={markAllAsRead}>
                <i className="fa-regular fa-circle-check"></i> Mark all as read
              </button>
            )}
            <img
              src="https://cdn-icons-png.flaticon.com/128/17819/17819444.png"
              width={25}
              onClick={handleNotificationClose}
            />
          </div>
        </div>

        <div className="notification-tabs">
          <button
            className={`tab ${activeTab === 'all' ? 'active' : ''}`}
            onClick={() => setActiveTabState('all')}
          >
            All <span>{notifications.length}</span>
          </button>
          <button
            className={`tab ${activeTab === 'application' ? 'active' : ''}`}
            onClick={() => setActiveTabState('application')}
          >
            Application
          </button>
          <button
            className={`tab ${activeTab === 'message' ? 'active' : ''}`}
            onClick={() => setActiveTabState('message')}
          >
            {role === 'student' ? 'Inbox' : 'Messages'}
          </button>
        </div>

        <div
          className="notifications"
          style={{
            maxHeight: filteredNotifications.length <= 1 ? '100px' : '260px',
          }}
        >
          {filteredNotifications.length > 0 ? (
            filteredNotifications.map(notif => (
              <div
                className="notification"
                onClick={() => {
                  if (!notif.is_read) {
                    markAsRead(notif.id);
                  }
                  if (
                    role === 'sponsor' &&
                    notif.title === 'New Application Match Found' &&
                    !notif.is_read
                  ) {
                    setShowDropdown(false);
                    setPage('page');
                    dispatch(setActiveTab('page'));
                  } else {
                    OpenNotificationDetails(notif);
                  }
                }}
                key={notif.id}
              >
                <div className="notification_card">
                  <div className="notification_icon">
                    <i className={`icon ${getIconClass(notif.title)}`}></i>
                  </div>
                  <div className="notification_textBox">
                    <div className="notification_textContent">
                      <p className="notification_h1">{notif.title}</p>
                      <span className="notification_span">
                        {Number(notif.created_at[0])>0 ? notif.created_at : 'just now'}
                        {!notif.is_read && (
                          <span className="notification_read"></span>
                        )}
                      </span>
                    </div>
                    <p className="notification_p">
                      {truncateMessage(notif.title==='To sponsor' || notif.title==='Message from a Student'?'a thank you message': notif.message, 40)}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <div className=" mt-4 notification-content d-flex justify-content-center">
                <p>
                  <b>No notifications at the moment</b>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* ------ Modal -------- */}
      {open && (
        <CommonModal open={open} handleClose={handleClose}  width='400px'>
          <NotificationMessage
            selectedNotification={selectedNotification}
            setopen={setopen}
            handleClose={handleClose}
            getIconClass={getIconClass}
          />
        </CommonModal>
      )}
    </>
  );
}

export default Notifications;
