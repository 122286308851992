// Functionality of this code : This page is for Adding Students
// Input : Student's email, Institute name and Random Access Code
// Output : A toast message
// Written by: Akash Shah

import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import axios from 'axios';
import checkAuth from '../../utils/checkAuth';
import Loading from '../../common/Loader/Loader';

const AddStudentForm = props => {
  const orgName = useSelector(state => state.user.name);
  const { setopen } = props;
  const [loading, setLoading] = useState(false);
  
  const topUniversitiesSingapore = useSelector(
    state => state.user.universities || []
  );
   
  const [formData, setFormData] = useState({
    email: '',
    instituteName: '',
    accessCode: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    instituteName: '',
    accessCode: '',
  });

  const generateAccessCode = () => {
    if (!orgName) {
      toast.error('Organization name is required.');
      return;
    }

    const orgPrefix = orgName.toUpperCase().substring(0, 3); // Use first 3 letters of orgName
    const randomPart = Math.random().toString(36).substring(2, 8).toUpperCase(); // Generate random alphanumeric string
    const code = orgPrefix + randomPart;
    setFormData({ ...formData, accessCode: code });
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate Email
    if (!formData.email) newErrors.email = 'Email is required.';
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = 'Invalid email format.';

    // Validate Institute Name
    if (!formData.instituteName)
      newErrors.instituteName = 'Institute Name is required.';

    // Validate Access Code
    if (!formData.accessCode) newErrors.accessCode = 'Access Code is required.';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = e => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const addStudent = async () => {
    if (validateForm()) {
      setLoading(true);
      const { idToken } = await checkAuth();
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PROXY}/user/org/add-student`,
          {
            StudentMail: formData.email,
            university: formData.instituteName.title,
            accessCode: formData.accessCode,
            status: 1,
            referringOrganization: orgName,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
          }
        );

        if (response.status === 200) {
          toast.success('Student added successfully!');
          setFormData({
            email: '',
            instituteName: '',
            accessCode: '',
          });
          setopen(false);
          setLoading(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error(error.response.data.message);
        } else {
          console.error(error.response?.data || error.message);
          toast.error('An error occurred. Please try again later.');
          setopen(false);
        }
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading && <Loading.AddingStudentByOrgLoader />}
      <div className="container mt-4">
        <div className="form-header">ADD STUDENT FORM</div>
        <form className="needs-validation" noValidate="">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="email">Student's email</label>
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="instituteName">Institute Name</label>
              <Autocomplete
                id="instituteName"
                size="small"
                options={topUniversitiesSingapore}
                getOptionLabel={option => option.title}
                value={formData.instituteName}
                onChange={(e, newValue) => {
                  setFormData({
                    ...formData,
                    instituteName: newValue || '',
                  });
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Institute"
                    fullWidth
                  />
                )}
              />
              {errors.instituteName && (
                <div className="text-danger">{errors.instituteName}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 mb-3">
              <label htmlFor="accessCode">Generate Access Code</label>
              <input
                type="text"
                className="form-control"
                id="accessCode"
                placeholder="Access Code"
                value={formData.accessCode}
                onChange={handleInputChange}
                disabled
              />
              {errors.accessCode && (
                <div className="text-danger">{errors.accessCode}</div>
              )}
            </div>
            <div className="col-md-4 mb-3 d-flex align-items-end">
              <button
                type="button"
                className="btn btnn btn-block"
                onClick={generateAccessCode}
              >
                Generate Code
              </button>
            </div>
          </div>

          <div className="text-center mb-3">
            <button type="button" className="btn btnn" onClick={addStudent}>
              Add Student
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddStudentForm;
