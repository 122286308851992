// Functionality of this code : This page contains the Organization profile section
// Input : None
// Output : Fetch organization details
// Written by: Akash Shah
/* global jsQR */

import { Button, TextField, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as firebase from 'firebase';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';
import {
  fetchUniversities,
  fetchUserPreferences,
  updateUserName,
} from '../../store/actions/userActions';
import orglogo from '../../assets/orgAssets/orgLogo.png';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../common/Loader/Loader';
import CancelIcon from '@mui/icons-material/Cancel';
import QrScanner from 'qr-scanner';

const topUniversitiesSingapore = [
  { title: 'National University of Singapore (NUS)' },
  { title: 'Nanyang Technological University (NTU)' },
  { title: 'Singapore Management University (SMU)' },
  { title: 'Singapore University of Technology and Design (SUTD)' },
  { title: 'Singapore Institute of Technology (SIT)' },
  { title: 'Singapore University of Social Sciences (SUSS)' },
  { title: 'INSEAD Asia Campus' },
  { title: 'Duke-NUS Medical School' },
  { title: 'Yale-NUS College' },
  { title: 'LASALLE College of the Arts' },
  { title: 'Nanyang Academy of Fine Arts (NAFA)' },
  { title: 'PSB Academy' },
  { title: 'Kaplan Higher Education Academy' },
  { title: 'James Cook University Singapore' },
  { title: 'Curtin Singapore' },
  { title: 'Amity Global Institute' },
  { title: 'Management Development Institute of Singapore (MDIS)' },
  { title: 'TMC Academy' },
  { title: 'East Asia Institute of Management (EASB)' },
  { title: 'Dimensions International College' },
];

const useStyles = makeStyles(theme => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  button: {
    width: 120,
  },
  input: {
    background: 'white',
  },
}));

//University Profile page
export const OrgProfile = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    country: { title: 'Singapore' },
    state: { title: 'Singapore' },
    address: '',
    universities: '',
    account_name: '',
    account_number: '',
    bank_name: '',
    domain: '',
    countries: [{ title: '' }],
    states: [{ title: '' }],
    qrcode_filename: '',
    upi_id: '',
    orgname: '',
  });

  // const [university, setUniversity] = React.useState({ name: '' });
  const [university, setUniversity] = React.useState([]);
  const [country, setCountry] = React.useState({ title: 'Singapore' });
  const [mystate, setMyState] = React.useState({ title: 'Singapore' });
  const [error, setError] = React.useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const formData = new FormData();

  const handleButtonClick = () => {
    // Programmatically trigger the file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = e => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      if (!fileType.startsWith('image/')) {
        e.target.value = '';
        alert('Only image files are allowed!');
        return;
      }
    }
    console.log('Selected File Before QR Check:', selectedFile);
    handleQRCheck(selectedFile);
  };

  const handleRemoveFile = () => {
    setState(prevState => ({ ...prevState, qrcode_filename: null }));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleQRCheck = file => {
    console.log('File Inside QR Check:', file);
    QrScanner.scanImage(file)
      .then(qrData => {
        const upiInfo = parseUPILink(qrData);
        if (!upiInfo.upiID || !upiInfo.name) {
          toast.error('Please upload a valid UPI QR Code');
          setState(prevState => ({ ...prevState, qrcode_filename: null }));
        } else {
          setState(prevState => ({
            ...prevState,
            qrcode_filename: file,
            upi_id: upiInfo.upiID,
            orgname: upiInfo.name,
          }));
        }
      })
      .catch(() => {
        toast.error('Please upload a valid QR Code.');
      });
  };

  const parseUPILink = upiLink => {
    const urlParams = new URLSearchParams(upiLink.replace('upi://pay?', ''));
    return {
      upiID: urlParams.get('pa') || null,
      name: urlParams.get('pn') || null,
    };
  };

  useImperativeHandle(ref, () => ({
    getAlert() {
      submitPref();
    },
  }));

  useEffect(() => {
    if (props.open) {
      fetchOrgProfile();
    }
  }, [props.open]);

  const fetchOrgProfile = async () => {
    try {
      setLoading(true);

      // Ensure the user is authenticated
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        throw new Error('User is not authenticated');
      }

      // Get the ID token
      const idToken = await currentUser.getIdToken();

      // Fetch data from the API
      const response = await axios.get(
        `${process.env.REACT_APP_PROXY}/user/pref`,
        {
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
        }
      );

      const data = response.data;

      if (!data.success) {
        setError(data.message);
        return;
      }

      const orgProfiles = data.message.orgProfiles;
      if (orgProfiles && orgProfiles.length > 0) {
        const orgProfile = orgProfiles[0];

        // Set university
        if (orgProfile.university?.length > 0) {
          setUniversity(orgProfile.university);
        }

        // Set country
        if (state.countries?.length > 0) {
          const country = state.countries.find(
            d => d.title === orgProfile.country
          );
          if (country) {
            setCountry(country);
          }
        }

        // Set state
        if (state.states?.length > 0) {
          const singaporeState = state.states.find(
            d => d.title === orgProfile.state
          );
          if (singaporeState) {
            setMyState(singaporeState);
          }
        }
        // Update state
        setState(prevState => ({
          ...prevState,
          ...orgProfile, // Directly spread the profile fields
          orgname: orgProfile.name, // Map "name" to "orgname"
        }));
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching organization profile:', error);
      setError('Failed to fetch organization profile. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Function to validate required fields and show only one error at a time
  const validateFields = () => {
    const nameRegex = /^[a-zA-Z\s]{3,100}$/; // Allows only letters and spaces, 3-100 chars
    const accountNumberRegex = /^[0-9]{6,12}$/; // Only digits, 6-12 length
    const upiRegex = /^[a-zA-Z0-9.\-_]{3,50}@[a-zA-Z]{2,20}$/; // Basic UPI format
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,10}$/; // Domain validation

    if (
      !mystate.title ||
      mystate.title.length < 2 ||
      mystate.title.length > 50
    ) {
      return 'State must be between 2 and 50 characters.';
    }
    if (
      !country.title ||
      country.title.length < 2 ||
      country.title.length > 50
    ) {
      return 'Country must be between 2 and 50 characters.';
    }
    if (!state.orgname || !nameRegex.test(state.orgname)) {
      return 'Organization name must contain only letters (3-100 characters).';
    }
    if (university.length < 1) {
      return 'At least one University name is required.';
    }
    if (
      !state.address ||
      state.address.length < 5 ||
      state.address.length > 255
    ) {
      return 'Address must be between 5 and 255 characters.';
    }
    if (!state.upi_id || !upiRegex.test(state.upi_id)) {
      return 'UPI ID is invalid. Format: example@bank';
    }
    if (!state.qrcode_filename && !props.open) {
      return 'Please upload organization QR Code.';
    }
    if (!state.account_name || !nameRegex.test(state.account_name)) {
      return 'Account Name must contain only letters (3-100 characters).';
    }
    if (
      !state.account_number ||
      !accountNumberRegex.test(state.account_number)
    ) {
      return 'Account Number must contain only numbers (6-12 digits).';
    }
    if (!state.bank_name || !nameRegex.test(state.bank_name)) {
      return 'Bank Name must contain only letters (3-50 characters).';
    }
    if (!state.domain || !domainRegex.test(state.domain)) {
      return 'Invalid domain format.';
    }

    return null; // All fields are valid
  };

  //  Update or Add Prefrence org
  // const submitPref = () => {
  //   const validationError = validateFields();
  //   // If validation fails, show the error and stop further execution
  //   if (validationError) {
  //     toast.error(validationError, { autoClose: 2000 });
  //     return;
  //   }
  //   setLoading(true);
  //   const uniqueFileName = state.qrcode_filename
  //     ? `${uuidv4()}${state.qrcode_filename.name}`
  //     : '';
  //   firebase
  //     .auth()
  //     .currentUser.getIdToken()
  //     .then(async function (idToken) {
  //       const requestOptions = {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           auth: idToken,
  //         },
  //         body: JSON.stringify({
  //           state: mystate.title,
  //           country: country.title,
  //           university: university,
  //           address: state.address,
  //           account_name: state.account_name,
  //           account_number: state.account_number,
  //           bank_name: state.bank_name,
  //           domain: state.domain,
  //           qrcode_filename: uniqueFileName,
  //           upi_id: state.upi_id,
  //           orgname: state.orgname,
  //         }),
  //       };

  //       if (!props.open) {
  //         const requestOptionsFile = {
  //           method: 'POST',
  //           headers: {
  //             auth: idToken,
  //           },
  //         };
  //         // Only upload the file if necessary (if file exists)
  //         if (state.qrcode_filename) {
  //           const formData = new FormData();
  //           formData.append('myFile', state.qrcode_filename); // Append file under 'myFile' field name
  //           formData.append('name', uniqueFileName); // Add the unique file name as another field

  //           // Log each form data field
  //           console.log('FormData Content:');
  //           for (let [key, value] of formData.entries()) {
  //             console.log(`${key}:`, value);
  //           }

  //           try {
  //             // Remove the "Content-Type" header since axios will automatically handle it
  //             await axios
  //               .post(
  //                 process.env.REACT_APP_PROXY + '/user/uploadQR',
  //                 formData, // Send the FormData containing the file
  //                 {
  //                   headers: {
  //                     auth: idToken, // Include the auth token for authorization
  //                   },
  //                 }
  //               )
  //               .then(res => {
  //                 toast.success('QR Uploaded Successfully', {
  //                   autoClose: 2000,
  //                 });
  //               });
  //           } catch (ex) {
  //             toast.error('Error in uploading QR code', { autoClose: 2000 });
  //             console.log('error', ex);
  //             return;
  //           }
  //         }
  //       }
  //       fetch(process.env.REACT_APP_PROXY + '/user/pref', requestOptions)
  //         .then(res => res.json())
  //         .then(data => {
  //           if (data.success) {
  //             setLoading(false);
  //             dispatch(updateUserName(state.orgname));
  //             dispatch(fetchUniversities(university));
  //             toast.success('Deatails updated Successfully');
  //             props.setPage('dashboard');
  //             // props.setOpen(false);
  //             // props.handleClose();
  //           } else {
  //             setLoading(false);
  //             toast.error(data.message, { autoClose: 2000 });
  //             setError(data.message);
  //             setState('');
  //             setUniversity('');
  //           }
  //         })
  //         .catch(function (error) {
  //           setLoading(false);
  //           toast.error(error, { autoClose: 2000 });
  //           setState('');
  //           setUniversity('');
  //         });
  //     });
  // };

  const submitPref = () => {
    let isMounted = true;
    const validationError = validateFields();
    if (validationError) {
      toast.error(validationError, { autoClose: 2000 });
      return;
    }

    setLoading(true);
    const uniqueFileName = state.qrcode_filename
      ? `${uuidv4()}${state.qrcode_filename.name}`
      : '';

    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (idToken) {
        if (!isMounted) return; // Prevent state update if unmounted

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
          body: JSON.stringify({
            state: mystate.title,
            country: country.title,
            university: university,
            address: state.address,
            account_name: state.account_name,
            account_number: state.account_number,
            bank_name: state.bank_name,
            domain: state.domain,
            qrcode_filename: uniqueFileName,
            upi_id: state.upi_id,
            orgname: state.orgname,
          }),
        };

        // Upload QR Code if available
        if (!props.open && state.qrcode_filename) {
          const formData = new FormData();
          formData.append('myFile', state.qrcode_filename);
          formData.append('name', uniqueFileName);

          try {
            await axios.post(
              `${process.env.REACT_APP_PROXY}/user/uploadQR`,
              formData,
              {
                headers: { auth: idToken },
              }
            );
            toast.success('QR Uploaded Successfully', { autoClose: 2000 });
          } catch (uploadError) {
            toast.error('Error in uploading QR code', { autoClose: 2000 });
            console.error('Upload QR error:', uploadError);
            return;
          }
        }
        
        // Submit Organization Preferences
        try {
          const response = await fetch(
            process.env.REACT_APP_PROXY + '/user/pref',
            requestOptions
          );
          if (!isMounted) return;

          const data = await response.json();
          if (data.success) {
            setLoading(false);
            dispatch(updateUserName(state.orgname));
            dispatch(fetchUniversities(university));
            toast.success('Details updated Successfully');

            if (props.setPage) props.setPage('dashboard');
            if (props.setOpen) props.setOpen(false);
            if (props.handleClose) props.handleClose();
          } else {
            setLoading(false);
            toast.error(data.message, { autoClose: 2000 });
            setError(data.message);
            setState('');
            setUniversity('');
          }
        } catch (error) {
          if (!isMounted) return;
          setLoading(false);
          toast.error(error.message, { autoClose: 2000 });
          setState('');
          setUniversity('');
        }
      });

    return () => {
      isMounted = false; // Cleanup when the component unmounts
    };
  };

  return (
    <>
      {loading && <Loading.orgLoader />}
      <div className="admin-modal2 preferences-modal">
        <div className="form-bottom">
          <div
            className="d-flex align-items-center mt-3"
            style={{ justifyContent: 'space-between' }}
          >
            {/* Logo */}
            <img
              src={orglogo}
              alt=""
              style={{
                border: '1px solid',
                borderRadius: '60px',
                width: '80px',
              }}
            />

            {/* Heading */}
            <h2
              style={{
                flexGrow: 1, // Allow the heading to take up remaining space
                textAlign: 'center', // Center the heading
                margin: 0, // Remove default margin
                fontWeight: 'bold', // Bold heading
              }}
            >
              Organization Preferences
            </h2>
          </div>

          <form action="">
            <div className="form-group">
              <input
                type="text"
                placeholder="Organization name"
                value={state.orgname}
                onChange={e => {
                  e.persist();
                  setState(prevState => {
                    const orgname = e.target.value;
                    return { ...prevState, orgname };
                  });
                }}
              />
            </div>
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={topUniversitiesSingapore}
              getOptionLabel={option => option.title}
              onChange={(event, value) => setUniversity(value)}
              value={university}
              Add
              Universities
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Add Universities"
                  placeholder="Universities"
                />
              )}
            />
            <div className="form-group">
              <input
                type="text"
                placeholder="Address"
                value={state.address}
                onChange={e => {
                  e.persist();
                  setState(prevState => {
                    const address = e.target.value;
                    return { ...prevState, address };
                  });
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Upli Id"
                value={state.upi_id}
                onChange={e => {
                  e.persist();
                  setState(prevState => {
                    const upi_id = e.target.value;
                    return { ...prevState, upi_id };
                  });
                }}
              />
            </div>
            {/* QR Code on the right */}
            {props.open && (
              <div className="col-md-4 mb-3">
                <label htmlFor="qrCode">QR Code</label>
                <br />
                <img
                  src={`https://storage.googleapis.com/prodprodsql/uploads/${state.qrcode_filename}`}
                  alt="QR Code"
                  className="img-fluid"
                  id="qrCode"
                  width={100}
                />
              </div>
            )}
            {!props.open && (
              <span>
                {/* Hidden File Input */}
                <input
                  accept="image/*"
                  className={classes?.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />

                {/* Upload Button */}
                <Button
                  style={{ width: '200px' }}
                  variant="contained"
                  color="secondary"
                  className={classes?.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={handleButtonClick}
                >
                  Upload QR Code
                </Button>
              </span>
            )}
            {/* Show selected file */}
            {!props.open && state.qrcode_filename && (
              <div
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span>{state.qrcode_filename.name}</span>
                <IconButton
                  onClick={handleRemoveFile}
                  size="small"
                  color="error"
                >
                  <CancelIcon />
                </IconButton>
              </div>
            )}
            <div className="form-group">
              <input
                type="text"
                placeholder="Account Name"
                value={state.account_name}
                onChange={e => {
                  e.persist();
                  setState(prevState => {
                    const account_name = e.target.value;
                    return { ...prevState, account_name };
                  });
                }}
              />
            </div>
            <div className="form-group">
              <input
                type=" number"
                placeholder="Account No."
                value={state.account_number}
                onChange={e => {
                  e.persist();
                  setState(prevState => {
                    const account_number = e.target.value;
                    return { ...prevState, account_number };
                  });
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Name of Bank"
                value={state.bank_name}
                onChange={e => {
                  e.persist();
                  setState(prevState => {
                    const bank_name = e.target.value;
                    return { ...prevState, bank_name };
                  });
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Domain"
                value={state.domain}
                onChange={e => {
                  e.persist();
                  setState(prevState => {
                    const domain = e.target.value;
                    return { ...prevState, domain };
                  });
                }}
              />
            </div>
            {!props.disabled ? (
              <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
                <Button onClick={submitPref} color="primary">
                  Submit
                </Button>
              </Box>
            ) : (
              ''
            )}
          </form>
        </div>
      </div>
    </>
  );
});
