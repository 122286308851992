// Functionality of this code : This page is for showing data in form of charts
// Input :  None
// Output :Data of students and organzation 
// Written by: Akash Shah

import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { useMediaQuery, useTheme } from '@mui/material';
import './css/org.css';
import { useSelector } from 'react-redux';

const OrgChart = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Set dynamic height based on screen size.
  const getChartHeight = () => {
    if (isExtraSmallScreen) return 240;
    if (isSmallScreen) return 280;
    if (isMobile) return 314;
    return 340;
  };

  // Get API data from Redux.
  const apiData = useSelector(state => state.organizationDetails.Alldetails);

  // State for the full monthly summary chart data (all 12 months)
  const [fullSummaryChartData, setFullSummaryChartData] = useState(null);
  // State for the need type breakdown data (only total request amounts)
  const [needTypeChartData, setNeedTypeChartData] = useState(null);

  // Fixed array of month names.
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  useEffect(() => {
    if (apiData) {
      // 1. Process the monthly summary data from all student applications.
      // Initialize an object for all 12 months.
      const monthlyData = {};
      monthNames.forEach(month => {
        monthlyData[month] = {
          totalApplications: 0,
          activeApplications: 0,
          fulfilledApplications: 0,
          totalDonations: 0,
        };
      });

      // Loop through each student and each application.
      if (apiData.data && Array.isArray(apiData.data)) {
        apiData.data.forEach(student => {
          if (student.applications && Array.isArray(student.applications)) {
            student.applications.forEach(application => {
              const appDate = new Date(application.ApplicationDate);
              const monthLabel = monthNames[appDate.getMonth()];
              monthlyData[monthLabel].totalApplications += 1;
              if (application.status === 'Active') {
                monthlyData[monthLabel].activeApplications += 1;
              }
              if (application.status === 'Fullfilled') {
                monthlyData[monthLabel].fulfilledApplications += 1;
              }
              monthlyData[monthLabel].totalDonations +=
                Number(application.donationAmount) || 0;
            });
          }
        });
      }

      // Build arrays for each dataset.
      // (Note: We’re excluding "Total Donations" per your instruction.)
      const labels = monthNames; // All 12 months.
      const totalApplicationsArr = labels.map(
        label => monthlyData[label].totalApplications
      );
      const activeApplicationsArr = labels.map(
        label => monthlyData[label].activeApplications
      );
      const fulfilledApplicationsArr = labels.map(
        label => monthlyData[label].fulfilledApplications
      );

      const summaryData = {
        labels,
        datasets: [
          {
            label: 'Total Applications',
            data: totalApplicationsArr,
            backgroundColor: '#4ac0c0',
          },
          {
            label: 'Active Applications',
            data: activeApplicationsArr,
            backgroundColor: '#67a4f0',
          },
          {
            label: 'Fulfilled Applications',
            data: fulfilledApplicationsArr,
            backgroundColor: '#a17dc3',
          },
        ],
      };

      // 2. Process the need type breakdown data.
      // Aggregate only the request amounts per need type.
      const needTypeAggregation = {};
      if (apiData.data && Array.isArray(apiData.data)) {
        apiData.data.forEach(student => {
          if (student.applications && Array.isArray(student.applications)) {
            student.applications.forEach(application => {
              const needType = application.needType;
              if (!needTypeAggregation[needType]) {
                needTypeAggregation[needType] = { totalRequest: 0 };
              }
              needTypeAggregation[needType].totalRequest +=
                application.requestAmount;
            });
          }
        });
      }

      const needTypeLabels = Object.keys(needTypeAggregation);
      const totalRequestValues = needTypeLabels.map(
        label => needTypeAggregation[label].totalRequest
      );

      // Set different colors for each need type (e.g., Books, Tuition, Glasses).
      const backgroundColors = needTypeLabels.map(label => {
        // Use case-insensitive matching
        if (label.toLowerCase() === 'books') return '#4ac0c0';
        if (label.toLowerCase() === 'tuition') return '#67a4f0';
        if (label.toLowerCase().includes('glasses')) return '#a17dc3';
        // Fallback color for any other need types.
        return '#f0a167';
      });

      const needData = {
        labels: needTypeLabels,
        datasets: [
          {
            label: 'Total Request Amount',
            data: totalRequestValues,
            backgroundColor: backgroundColors,
          },
        ],
      };

      setFullSummaryChartData(summaryData);
      setNeedTypeChartData(needData);
    }
  }, [apiData]);

  // Shared chart options with an in‑chart title.
  const commonOptions = {
    responsive: true,
    animation: {
      duration: 100, // Slightly reduced animation duration for smoothness
    },
    hover: {
      animationDuration: 100, // Faster hover effect
    },
    plugins: {
      tooltip: {
        enabled: true,
        animation: false, // Disable tooltip fade-in delay
        mode: 'index', // Show all data points at the same index
        intersect: false, // Ensure tooltips appear on hover
      },
      title: {
        display: true, // ✅ Ensure the title is displayed
        text: '', // ✅ Set the title text
        font: {
          size: 16, // Optional: Adjust title font size
        },
      },
      legend: {
        display: true,
      },
    },
    interaction: {
      mode: 'index', // Show all tooltip details together
      intersect: false, // Ensure tooltips appear together
    },
    elements: {
      bar: {
        borderWidth: 0, // Removes border if any transition issue
      },
    },
  };

  if (!fullSummaryChartData || !needTypeChartData) {
    return <div>Loading Chart...</div>;
  }

  // --- Compute the latest 5 months ---
  // Get the current month index (0-indexed: Jan=0, ..., Dec=11).
  const currentMonthIndex = new Date().getMonth();
  // Build an array of the latest 5 month indices (accounting for year wrap-around).
  const latestMonthIndices = [];
  for (let i = 4; i >= 0; i--) {
    latestMonthIndices.push((currentMonthIndex - i + 12) % 12);
  }
  // Get the month labels for these indices.
  const latestLabels = latestMonthIndices.map(i => monthNames[i]);

  // For each dataset in the monthly summary, extract the values for these 5 months.
  const filteredDatasets = fullSummaryChartData.datasets.map(dataset => ({
    ...dataset,
    data: latestMonthIndices.map(i => dataset.data[i]),
  }));

  const displayedSummaryData = {
    labels: latestLabels,
    datasets: filteredDatasets,
  };

  return (
    <div className="chart-container">
      {/* Monthly Summary Chart (latest 5 months) */}
      <div className="chart">
        <Bar
          options={{
            ...commonOptions,
            plugins: {
              ...commonOptions.plugins,
              title: {
                ...commonOptions.plugins.title,
                text: 'Monthly Summary (Latest 5 Months)',
              },
            },
          }}
          data={displayedSummaryData}
          height={getChartHeight()}
          width={531}
        />
      </div>

      {/* Need Type Breakdown Chart */}
      <div className="chart">
        <Bar
          options={{
            ...commonOptions,
            plugins: {
              ...commonOptions.plugins,
              title: {
                ...commonOptions.plugins.title,
                text: 'Need Type Breakdown: Total Request Amount',
              },
            },
          }}
          data={needTypeChartData}
          height={getChartHeight()}
          width={531}
        />
      </div>
    </div>
  );
};

export default OrgChart;
