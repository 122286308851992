export const updateUserName = name => ({
  type: 'UPDATE_USER_NAME',
  payload: name,
});

export const fetchUserPreferences = preferences => ({
  type: 'FETCH_USER_PREFERENCES',
  payload: preferences,
});

export const fetchUniversities = universities => ({
  type: 'FETCH_UNIVERSITIES',
  payload: universities,
});

export const SetUserApplication = data => ({
  type: 'SET_USER_APPLICATION_DETAILS',
  payload: data,
});

export const resetUser = () => ({
  type: 'RESET_USER',
});

export const SetUserTokenResult = userId => ({
  type: 'SET_USER_TOKEN_RESULT',
  payload: userId,
});
