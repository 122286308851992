import { useState, useEffect } from 'react';

const useFilter = (initialData, categories) => {
  const [filteredData, setFilteredData] = useState(initialData);
  const [category, setCategory] = useState(categories[0]);

  useEffect(() => {
    if (JSON.stringify(filteredData) !== JSON.stringify(initialData)) {
      setFilteredData(initialData);
    }
  }, [initialData]);

  const setQuery = query => {
    const lowerCaseQuery = query.toLowerCase();

    if (!query) {
      setFilteredData(initialData);
      return;
    }

    const filtered = initialData.filter(student => {
      const key =
        category === 'Student Name'
          ? 'name'
          : category === 'Institute'
          ? 'institute'
          : category === 'University'
          ? 'university'
          : category === 'Access Code'
          ? 'accessCode'
          : category === 'Student ID'
          ? 'studentId'
          : category === 'Student Email'
          ? 'email'
          : category === 'Student Email'
          ? 'studentMail'
          : 'codes';

      const value = student[key]?.toString().toLowerCase() || '';
      return value.includes(lowerCaseQuery);
    });
    setFilteredData(filtered);
  };

  return { filteredData, setCategory, setQuery };
};

export default useFilter;
