import React from 'react';
import './applicationdetails.css';
import { GetOnlyDate } from '../../utils/dateUtils';
import school from '../../assets/icon1.png';
import books from '../../assets/icon2.png';
import glasses from '../../assets/icon3.png';
import { getStatusColor } from '../../utils/basic';

const ApplicationDetails = props => {
  const { application, handleClose } = props;



  return (
    <>
      <div className="container mt-4">
        {application ? (
          <>
            <div className="form-header">
              Application Details (Status : {application.status})
              <span
                style={{
                  display: 'inline-block',
                  marginLeft: '10px',
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  backgroundColor: getStatusColor(application.status),
                }}
              ></span>
            </div>
            <form className="needs-validation" noValidate="">
              <div className="row">
                {/* QR Code or relevant image can go here */}
                <div className="col-md-4 mb-3">
                  <label htmlFor="applicationQr">Application Need for</label>
                  <br/>
                  <img
                    src={
                      application.need_type === 'Books'
                        ? books
                        : application.need_type === 'Tuition'
                        ? school
                        : glasses
                    }
                    alt="Application QR"
                    className="img-fluid"
                    id="applicationQr"
                  />
                </div>

                {/* Application details on the left */}
                <div className="col-md-8 mb-3">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="applicationType">Application Type</label>
                      <input
                        type="text"
                        className="form-control"
                        id="applicationType"
                        value={application.need_type || 'Scholarship'}
                        readOnly
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="applicationNumber">
                        Application Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="applicationNumber"
                        value={application.application_no}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="dateFilled">Date Filled</label>
                      <input
                        type="text"
                        className="form-control"
                        id="dateFilled"
                        value={GetOnlyDate(application.request_date)}
                        readOnly
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="dueDate">Due Date</label>
                      <input
                        type="text"
                        className="form-control"
                        id="dueDate"
                        value={GetOnlyDate(application.need_dateline)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Payment Details */}
              <div className="row">
                {/* QR Code on the right */}
                <div className="col-md-4 mb-3">
                  <label htmlFor="qrCode">QR Code</label>
                  <br/>
                  <img
                    src={`https://storage.googleapis.com/prodprodsql/uploads/${application.payment_file}`}
                    alt="QR Code"
                    className="img-fluid"
                    id="qrCode"
                    width={100}
                  />
                </div>

                {/* Inputs on the left */}
                <div className="col-md-8 mb-3">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="amount">Amount need</label>
                      <input
                        type="text"
                        className="form-control"
                        id="amount"
                        value={application.need_amount}
                        readOnly
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="paymentUrl">Payment URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="paymentUrl"
                        value={application.payment_url}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="upiId">UPI ID</label>
                      <input
                        type="text"
                        className="form-control"
                        id="upiId"
                        defaultValue="your-upi-id@bank"
                        readOnly
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="invoiceNumber">Invoice Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="invoiceNumber"
                        value={application.invoice_number}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Future Pledge */}
              <div className="row form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="futurePledge">Future Pledge</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="futurePledge"
                    value={`${
                      application.future_sponsor_count
                        ? `You choose to sponsor ${application.future_sponsor_count} students in future`
                        : `You have chosen to volunteer ${application.volunteer_hours} hours daily, contributing your skills in ${application.skills_contributed_hours}.`
                    }`}
                    readOnly
                    rows="2"
                    cols="50"
                    style={{ resize: 'none' }}
                  />
                </div>
              </div>
              {/* Disabled Submit Button */}
              <div className="text-center mb-3">
                <button
                  className="btn btnn"
                  type="button"
                  onClick={() => handleClose()}
                >
                  <b>Close</b>
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="alert alert-info text-center">
            <p>
              There is a new update! Please click on the{' '}
              <strong>New Update</strong> button on the dashboard to see the
              updates.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default ApplicationDetails;
