const initialState = {
  AllStudentsCount: 0,
  Alldetails: {},
  applicationsCount: {},
};

const orgDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ORG_DETAILS':
      return {
        ...state,
        Alldetails: action.payload,
      };
    case 'SET_ALL_STUDENTS':
      return {
        ...state,
        AllStudentsCount: action.payload,
      };
    case 'SET_APPLICATION_COUNT':
      return {
        ...state,
        applicationsCount: action.payload,
      };
    default:
      return state;
  }
};

export default orgDetailsReducer;