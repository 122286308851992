// Functionality of this code : This page is for student details seen by org
// Input : None
// Output : student added by organization who used access code
// Written by: Akash Shah


import React from 'react';
import './css/org.css';
import bookImage from '../../assets/icon2.png';
import glassesImage from '../../assets/icon3.png';
import tuitionImage from '../../assets/icon1.png';
import { useMediaQuery, useTheme } from '@mui/material';

const StudentDetails = ({ application }) => {
  const { name, studentMail, accessCode, applications } = application;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getBadgeClass = status => {
    switch (status) {
      case 'Active':
        return 'badge-warning-light';
      case 'Fullfilled':
        return 'badge-success-light';
      case 'Inactive':
        return 'badge-secondary-light';
      default:
        return 'badge-secondary-light';
    }
  };

  const getImage = needType => {
    switch (needType) {
      case 'Books':
        return bookImage;
      case 'Tuition':
        return tuitionImage;
      case 'Pair Of Glasses':
        return glassesImage;
      default:
        return '/path-to-placeholder-image.png';
    }
  };

  return (
    <div className={`container ${isMobile ? 'p-3' : ''}`}>
      <div className="row text-secondary mb-3">
        <div className="col-md-4">
          <p className="fw-bold">Student Name</p>
          <p>{name}</p>
        </div>
        <div className="col-md-4">
          <p className="fw-bold">Email</p>
          <p>{studentMail}</p>
        </div>
        <div className="col-md-4">
          <p className="fw-bold">Access Code</p>
          <p
            className="badge badge-custom badge-blue fw-bold"
            style={{ fontSize: 'medium' }}
          >
            {accessCode}
          </p>
        </div>
      </div>
      <h2 className="fs-5 fw-bold mb-3">Applications</h2>
      <div className="gap-4">
        {applications.map((app, index) => (
          //
          <div
            key={index}
            className="card-custom d-flex justify-content-between align-items-center position-relative p-3 shadow-sm rounded border"
          >
            {/* Donated Amount Badge - Positioned at Top-Right */}
            {app.donationAmount > 0 && (
              <span className="position-absolute top-0 end-0 badge bg-warning text-dark px-3 py-1 rounded-start">
                🎉 Donated: ${app.donationAmount}
              </span>
            )}

            {/* Left Section - Icon & Application Info */}
            <div className="d-flex align-items-center">
              <div
                className="me-3 p-2 border rounded text-center bg-light"
                style={{ width: 70 }}
              >
                <img
                  src={getImage(app.needType)}
                  alt="Application"
                  width={50}
                />
              </div>
              <div>
                <p className="mb-1 text-primary fw-bold">
                  <span className="text-dark">Application</span> #
                  {app.applicationNumber}
                </p>
                <p className="mb-0 text">
                  Need Type · {app.needType}
                  <span
                    className="fw-bold bg-light text-success px-2 py-1 rounded-pill ms-2 border border-success shadow-sm"
                    style={{ fontSize: '14px' }}
                  >
                    💰 ${app.requestAmount}
                  </span>
                </p>
              </div>
            </div>

            {/* Status Badge */}
            <span className={`badge badge-custom ${getBadgeClass(app.status)}`}>
              {app.status}
            </span>
          </div>
        ))}
      </div>
      {/* <div className="mt-4 d-flex flex-wrap gap-2">
        <button className="btn btn-custom">Close</button>
      </div> */}
    </div>
  );
};

export default StudentDetails;
