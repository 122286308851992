// Functionality of this code : This page is for Showing details of students added by org
// Input : None
// Output : None
// Written by: Akash Shah

import React, { useEffect, useState } from 'react';
import './css/org.css';
import orgStudents from '../../assets/orgAssets/orgStudents.png';
import { Button } from '@mui/material';
import Table from '../../common/Table';
import TableFilter from '../../common/TableFilter';
import CommonModal from '../..//common/CommonModal';
import StudentDetails from './StudentDetails';
import AddStudentForm from './AddStudentForm';
import ViewAllStudents from './ViewAllStudents';
import useFilter from '../../hooks/useFilter';
import axios from 'axios';
import checkAuth from '../../utils/checkAuth';
import decrypt from '../../utils/DecryptData';
import Loading from '../../common/Loader/Loader';
import { useSelector } from 'react-redux';

const AddStudentPage = () => {
  const [requestPage, setRequestPage] = useState(0);
  const [requestRowsPerPage, setRequestRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [open, setopen] = useState(false);
  const [modal, setModal] = useState('');
  const handleClose = () => setopen(false);
  const [application, SetApplication] = useState([]);
  const [StudentData, SetStudentData] = useState([]);
  const [applicationsCount, setApplicationsCount] = useState({
    totalFulfilledApplications: 0,
    totalInactiveApplications: 0,
    totalActiveApplications: 0,
  });
  const OrgDetails = useSelector(state => state.organizationDetails.Alldetails); 

  const OpenApplicationDetails = details => {
    SetApplication(details);
    setModal('ApplicationDetails');
    setopen(true);
  };

  const OpenStudentForm = () => {
    setModal('StudentForm');
    setopen(true);
  };

  const ViewAllAddedStudents = () => {
    setModal('AllStudent');
    setopen(true);
  };

  const handleRequestChangePage = (event, newPage) => {
    setRequestPage(newPage);
  };

  const handleRequestChangeRowsPerPage = event => {
    setRequestRowsPerPage(parseInt(event.target.value, 10));
    setRequestPage(0);
  };

  // Columns for Student Info from Organization
  const requestStatusColumns = [
    'Student Name',
    'Institute',
    'Access Code',
    'Student ID',
    'Student Email',
    'Education Year',
    'Details',
  ];

  //Getting all students who used access Codes
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Decrypt student data
        const decryptedStudents = OrgDetails.data.map(student => {
          const decryptedEmail = decrypt(student.studentMail);
          const maskedEmail = decryptedEmail.replace(/^(.{2}).*@/, '$1XXXX');

          return {
            ...student,
            accessCode: decrypt(student.accessCode),
            studentMail: maskedEmail,
          };
        });

        // Set applications count
        setApplicationsCount({
          totalFulfilledApplications: OrgDetails.totalFulfilledApplications,
          totalInactiveApplications: OrgDetails.totalInactiveApplications,
          totalActiveApplications: OrgDetails.totalActiveApplications,
        });

        // Update student data
        SetStudentData(decryptedStudents);
      } catch (error) {
        console.error('Error fetching students:', error);
      } finally {
        setLoading(false); // Ensure loading state is updated last
      }
    };

    fetchData();
  }, []);

  const categories = ['Student Name', 'Institute', 'Access Code', 'Student ID'];
  // Use the custom filtering hook
  const { filteredData, setCategory, setQuery } = useFilter(
    StudentData,
    categories
  );
  // Function to render a row
  const renderRequestStatusRow = rowData => {
    return (
      <>
        <td>{rowData.name}</td>
        <td>{rowData.institute}</td>
        <td>{rowData.accessCode}</td>
        <td>{rowData.studentId}</td>
        <td>{rowData.studentMail}</td>
        <td>{`${rowData.startYear} - ${rowData.endYear}`}</td>
        <td>
          <center>
            <button
              href="#"
              className="ViewDetailsbutton"
              style={{ '--clr': '#7808d0' }}
              onClick={() => OpenApplicationDetails(rowData)}
            >
              <span className="ViewDetailsbutton__icon-wrapper">
                <svg
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ViewDetailsbutton__icon-svg"
                  width={10}
                >
                  <path
                    d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                    fill="currentColor"
                  />
                </svg>
                <svg
                  viewBox="0 0 14 15"
                  fill="none"
                  width={10}
                  xmlns="http://www.w3.org/2000/svg"
                  className="ViewDetailsbutton__icon-svg ViewDetailsbutton__icon-svg--copy"
                >
                  <path
                    d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              View Details
            </button>
          </center>
        </td>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Loading.orgLoader />
      ) : (
        <div className="main">
          <div className="AddStudentcontainer container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 main_card">
                <img
                  src={orgStudents}
                  alt="imgIcon"
                  className="card-image"
                  width={60}
                />
                <div className="detailCard" style={{ textAlign: 'center' }}>
                  {/* <span style={{ fontSize: '1.3rem', fontFamily: 'fantasy' }}>
                23
              </span> */}
                  <p>Total Students</p>
                </div>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#4CAF50',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: '#45A049',
                    },
                    padding: '4px 15px',
                    fontSize: '14px',
                    borderRadius: '8px',
                    width: '100%',
                  }}
                  onClick={ViewAllAddedStudents}
                >
                  View
                </Button>
              </div>
              <div className="col-lg-1 col-md-1 d-none d-md-block"></div>
              <div className="col-lg-8 col-md-9 col-sm-12 col-12 main_card">
                <div className="row">
                  <div className="col-md-3 col-sm-3 col-12 mb-3">
                    <div className="detailCard" style={{ textAlign: 'center' }}>
                      <span
                        style={{ fontSize: '1.3rem', fontFamily: 'fantasy' }}
                      >
                        Add Student
                      </span>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: '#4CAF50',
                          color: '#FFFFFF',
                          '&:hover': {
                            backgroundColor: '#45A049',
                          },
                          padding: '4px 15px',
                          fontSize: '14px',
                          borderRadius: '8px',
                          width: '100%',
                        }}
                        onClick={OpenStudentForm}
                      >
                        Add Student
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-12 mb-3">
                    <div className="detailCard" style={{ textAlign: 'center' }}>
                      <span
                        style={{ fontSize: '1.3rem', fontFamily: 'fantasy' }}
                      >
                        {applicationsCount.totalActiveApplications}
                      </span>
                      <p>Active Applications</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-12 mb-3">
                    <div className="detailCard" style={{ textAlign: 'center' }}>
                      <span
                        style={{ fontSize: '1.3rem', fontFamily: 'fantasy' }}
                      >
                        {applicationsCount.totalInactiveApplications}
                      </span>
                      <p>Inactive Applications</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-12 mb-3">
                    <div className="detailCard" style={{ textAlign: 'center' }}>
                      <span
                        style={{ fontSize: '1.3rem', fontFamily: 'fantasy' }}
                      >
                        {applicationsCount.totalFulfilledApplications}
                      </span>
                      <p>Fullfilled Applications</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Table */}
          <section className="OrgStudent-section">
            <div className="container">
              <div className=" row mb-3">
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                  <b className="fs-4 ">Active Students Information</b>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 col-2"></div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <TableFilter
                    setCategory={setCategory}
                    setQuery={setQuery}
                    categories={categories}
                  />
                </div>
              </div>
              <div className="shadow-box">
                <div className="table-top table-sec">
                  <Table
                    columns={requestStatusColumns}
                    rows={filteredData}
                    page={requestPage}
                    rowsPerPage={requestRowsPerPage}
                    handleChangePage={handleRequestChangePage}
                    handleChangeRowsPerPage={handleRequestChangeRowsPerPage}
                    renderRow={renderRequestStatusRow}
                    loading={loading}
                    role="organization"
                  />
                </div>
              </div>
            </div>
          </section>
          {/* ------ Modal -------- */}
          {open && (
            <CommonModal
              open={open}
              handleClose={handleClose}
              height={
                modal === 'StudentForm'
                  ? '445px'
                  : modal === 'AllStudent'
                  ? 'fix'
                  : '600px'
              }
              width={modal === 'AllStudent' ? '682px' : ''}
            >
              {modal === 'StudentForm' ? (
                <AddStudentForm setopen={setopen} />
              ) : modal === 'AllStudent' ? (
                <ViewAllStudents />
              ) : (
                <StudentDetails application={application} />
              )}
            </CommonModal>
          )}
        </div>
      )}
    </>
  );
};

export default AddStudentPage;
