import React from 'react';
import stylesPencil from './PencilLoader.module.css';
import stylesSponsor from './SponsorLoader.module.css';
import ApplicationRequest from './ApplicationRequest.module.css';
import AddingStudentByOrg from './AddingStudentByOrg.module.css';
import Org from './Org.module.css';

// OverlayWrapper Component
const OverlayWrapper = ({ children }) => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    }}
  >
    {children}
  </div>
);

const PencilLoader = () => (
  <OverlayWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="200px"
      width="200px"
      viewBox="0 0 200 200"
      class={stylesPencil.pencil}
    >
      <defs>
        <clipPath id="pencil-eraser">
          <rect height="30" width="30" ry="5" rx="5"></rect>
        </clipPath>
      </defs>
      <circle
        transform="rotate(-113,100,100)"
        stroke-linecap="round"
        stroke-dashoffset="439.82"
        stroke-dasharray="439.82 439.82"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        r="70"
        class={stylesPencil.pencil__stroke}
      ></circle>
      <g transform="translate(100,100)" class={stylesPencil.pencil__rotate}>
        <g fill="none">
          <circle
            transform="rotate(-90)"
            stroke-dashoffset="402"
            stroke-dasharray="402.12 402.12"
            stroke-width="30"
            stroke="hsl(223,90%,50%)"
            r="64"
            class={stylesPencil.pencil__body1}
          ></circle>
          <circle
            transform="rotate(-90)"
            stroke-dashoffset="465"
            stroke-dasharray="464.96 464.96"
            stroke-width="10"
            stroke="hsl(223,90%,60%)"
            r="74"
            class={stylesPencil.pencil__body2}
          ></circle>
          <circle
            transform="rotate(-90)"
            stroke-dashoffset="339"
            stroke-dasharray="339.29 339.29"
            stroke-width="10"
            stroke="hsl(223,90%,40%)"
            r="54"
            class={stylesPencil.pencil__body3}
          ></circle>
        </g>
        <g
          transform="rotate(-90) translate(49,0)"
          class={stylesPencil.pencil__eraser}
        >
          <g class="pencil__eraser-skew">
            <rect
              height="30"
              width="30"
              ry="5"
              rx="5"
              fill="hsl(223,90%,70%)"
            ></rect>
            <rect
              clip-path="url(#pencil-eraser)"
              height="30"
              width="5"
              fill="hsl(223,90%,60%)"
            ></rect>
            <rect height="20" width="30" fill="hsl(223,10%,90%)"></rect>
            <rect height="20" width="15" fill="hsl(223,10%,70%)"></rect>
            <rect height="20" width="5" fill="hsl(223,10%,80%)"></rect>
            <rect
              height="2"
              width="30"
              y="6"
              fill="hsla(223,10%,10%,0.2)"
            ></rect>
            <rect
              height="2"
              width="30"
              y="13"
              fill="hsla(223,10%,10%,0.2)"
            ></rect>
          </g>
        </g>
        <g
          transform="rotate(-90) translate(49,-30)"
          class={stylesPencil.pencil__point}
        >
          <polygon points="15 0,30 30,0 30" fill="hsl(33,90%,70%)"></polygon>
          <polygon points="15 0,6 30,0 30" fill="hsl(33,90%,50%)"></polygon>
          <polygon points="15 0,20 10,10 10" fill="hsl(223,10%,10%)"></polygon>
        </g>
      </g>
    </svg>
  </OverlayWrapper>
);

const SponsorLoader = () => (
  <OverlayWrapper>
  <div className={stylesSponsor.loading}>
      <div className={stylesSponsor.loader}>
        <div className={stylesSponsor.cube}></div>
        <div className={stylesSponsor.cube}></div>
        <div className={stylesSponsor.cube}></div>
        <div className={stylesSponsor.cube}></div>
      </div>
  </div>
  </OverlayWrapper>
);

const AddingStudentByOrgLoader = () => (
  <OverlayWrapper>
    <div class={AddingStudentByOrg.typewriter}>
      <div class={AddingStudentByOrg.slide}>
        <i></i>
      </div>
      <div class={AddingStudentByOrg.paper}></div>
      <div class={AddingStudentByOrg.keyboard}></div>
      <div className={AddingStudentByOrg.text}>
        <b>Adding Student..</b>
      </div>
    </div>
  </OverlayWrapper>
);

const orgLoader = () => (
  <OverlayWrapper>
    <div>
      <div className={Org.loader}></div>
    </div>
  </OverlayWrapper>
);

const ApplicationRequestLoader = () => (
  <OverlayWrapper>
    <div class={ApplicationRequest.loader}>
      <div>
        <ul>
          <li>
            <svg fill="currentColor" viewBox="0 0 90 120">
              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
            </svg>
          </li>
          <li>
            <svg fill="currentColor" viewBox="0 0 90 120">
              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
            </svg>
          </li>
          <li>
            <svg fill="currentColor" viewBox="0 0 90 120">
              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
            </svg>
          </li>
          <li>
            <svg fill="currentColor" viewBox="0 0 90 120">
              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
            </svg>
          </li>
          <li>
            <svg fill="currentColor" viewBox="0 0 90 120">
              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
            </svg>
          </li>
          <li>
            <svg fill="currentColor" viewBox="0 0 90 120">
              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
            </svg>
          </li>
        </ul>
      </div>
      <span>Loading</span>
    </div>
  </OverlayWrapper>
);

const Loading = {
  PencilLoader,
  SponsorLoader,
  ApplicationRequestLoader,
  AddingStudentByOrgLoader,
  orgLoader,
};

export default Loading;
