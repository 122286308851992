// Functionality of this code : This page is for setting up new Student Profiles
// Input : None
// Output :
// Written by: Initial team VK, KR

import { useDispatch } from 'react-redux';
import { updateUserName } from '../store/actions/userActions';
import adminpng from '../assets/newui/admin-img.png';
import '../css/modal.css';
import '../css/style.css';
import * as firebase from 'firebase';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Avatar, Button, Checkbox, Table, TextField } from '@material-ui/core';
import React, { useEffect, useImperativeHandle } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import NewApp from './StudentApplication';
import CircularProgress from '@material-ui/core/CircularProgress';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import PaymentIcon from '@material-ui/icons/Payment';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import books from '../assets/books.jpg';
import housing from '../assets/housing.jpg';
import laptop from '../assets/laptop.jpg';
import school from '../assets/school.jpg';
import DialogTitle from '@material-ui/core/DialogTitle';
import insurance from '../assets/insurance.jpg';
import mealPlan from '../assets/mealPlan.jpg';
import airmiles from '../assets/airmiles.jpg';
import glasses from '../assets/glasses.png';
import mrt from '../assets/mrt.jpg';
import certification from '../assets/certification.jpg';
import accessibility from '../assets/accessibility.jpg';
import counsel from '../assets/counsel.png';
import others from '../assets/others.jpg';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import { toast } from 'react-toastify';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import secureLocalStorage from 'react-secure-storage';
import Spinner from '../utils/spinner';
import student from '../assets/user.png';
import sponsor from '../assets/sponsor.png';
import organization from '../assets/org.jpg';
import { getInitials } from '../utils/GetNameInitials';

// toast.configure();

const AutocompleteOverwrite = withStyles({
  root: {
    marginRight: 8,
    '& .MuiInputBase-root.Mui-disabled': {
      '& .MuiAutocomplete-popupIndicator': {
        color: 'rgba(0, 0, 0, 0)',
      },
      color: 'rgba(0, 0, 0, 1)',
    },
  },
})(Autocomplete);

const useStyles = makeStyles(theme => ({
  button: {
    width: 120,
  },
  input: {
    background: 'white',
  },
  space: {
    padding: '20 px',
    marginTop: 10,
  },
  blockboxDesk: {
    '@media (max-width: 959px)': {
      display: 'none',
    },
  },
  blockboxMb: {
    display: 'none',
    '@media (max-width: 959px)': {
      display: 'block',
    },
  },
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  // New styles for the top-admin container
  topAdminContainer: {
    textAlign: 'center',
    maxWidth: '600px',
    margin: '0 auto',
  },

  // New styles for the admin-img container
  adminImgContainer: {
    border: '10px solid #E18E92',
    borderRadius: '100%',
    width: '130px',
    height: '130px',
    display: 'inline-block',
  },

  // New styles for the form group label
  formGroupLabel: {
    display: 'block',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '21.09px',
    marginBottom: '8px',
  },

  // New styles for the form group input
  formGroupInput: {
    width: '100%',
    background: '#F5F5F5',
    borderRadius: '4px',
    border: 'none',
    padding: '19px 27px',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '18.75px',
    fontFamily: 'Roboto',
  },

  // New styles for the form group input placeholder
  formGroupInputPlaceholder: {
    color: '#000',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '18.75px',
  },

  // New styles for the form group input when focused
  formGroupInputFocused: {
    outline: 'none',
    boxShadow: 'navajowhite',
  },
}));

// Data capture of donar preference to split the donation amount across a period of time. For example monthly, quarterly or yearly.
const DonationTimesSlider = props => {
  const handleSliderChange = (event, newValue) => {
    props.setTimes_i_can_donate(newValue);
  };
  const useStyles2 = makeStyles({
    root: {
      width: 200,
      paddingRight: '15px',
    },
    input: {
      width: 42,
    },
  });
  const classes = useStyles2();
  return (
    <div className={classes.root}>
      <Typography color="primary" id="input-slider" gutterBottom>
        Split Donation
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <ThumbUpIcon />
        </Grid>
        <Grid item xs>
          <Slider
            min={1}
            step={1}
            max={12}
            marks
            valueLabelDisplay="auto"
            value={props.times_i_can_donate}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Typography id="input-slider" gutterBottom>
            {`${
              typeof props.times_i_can_donate === 'number'
                ? props.times_i_can_donate
                : 1
            }`}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

// Donor preference of the total amount they would like to donate
const DonationAmountSlider = props => {
  const handleSliderChange = (event, newValue) => {
    props.setDonateMax(newValue);
  };
  const useStyles2 = makeStyles({
    root: {
      width: 220,
      paddingLeft: '15px',
    },
    input: {
      width: 42,
    },
  });
  const classes = useStyles2();
  return (
    <div className={classes.root}>
      <Typography color="primary" id="input-slider" gutterBottom>
        Maximum Amount
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <LocalAtmIcon />
        </Grid>
        <Grid item xs>
          <Slider
            min={100}
            step={100}
            max={5000}
            scale={x => x ** 10}
            value={typeof props.donateMax === 'number' ? props.donateMax : 100}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Typography id="input-slider" gutterBottom>
            {`$${typeof props.donateMax === 'number' ? props.donateMax : 100}`}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

//Preferences/Profile of Students/Donors/University
export const Preferences = React.forwardRef((props, ref) => {
  const [name, setName] = React.useState('');
  const [amount, setAmount] = React.useState([]);
  const [valueCheck, setCheck] = React.useState();
  const [nod, setNod] = React.useState('');
  const [times_i_can_donate, setTimes_i_can_donate] = React.useState([]);
  const [donateMax, setDonateMax] = React.useState([]);
  const [country, setCountry] = React.useState({ title: 'Singapore' });
  const [mystate, setMyState] = React.useState({ title: 'Singapore' });
  // const [university, setUniversity] = React.useState({ name: 'Any' });
  const [university, setUniversity] = React.useState('Any');
  const [start_year, setStart_year] = React.useState({ title: '2021' });
  const [end_year, setEnd_year] = React.useState({ title: '2024' });
  const [need_type, setNeed_type] = React.useState([]);
  const [error, setError] = React.useState('');
  const [newElement, setNewElement] = React.useState([]);
  const [student_year, setStudent_year] = React.useState({ title: 'Any year' });
  const [field, setField] = React.useState({ title: 'Any' });
  const role = props.role;
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState('newprofile');
  const [loading, setLoading] = React.useState(false);
  
  const [shouldRerender, setShouldRerender] = React.useState(true);

  const [state, setState] = React.useState({
    username: '',
    password: '',
    country: '',
    state: '',
    university: 'Any',
    startyear: 2021,
    endyear: 2024,
    field: 'Any',
    name: '',
    amount: '',
    nod: '',
    needrange: 10000,
    // universities: [{ name: '' }],
    universities: ' ',
    countries: [{ title: 'Singapore' }],
    states: [{ title: 'Singapore' }],
    student_id: '',
    student_year: '',
  });
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    getAlert() {
      submitPref();
    },
  }));

  function getall() {
    if (state.countries.length >= 1 && state.states.length >=1) {
      let uni = '';
      let unis = {};
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(function (idToken) {
          const getRequestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
          };
          setLoading(true);
          fetch(process.env.REACT_APP_PROXY + '/user/pref', getRequestOptions)
            .then(res => res.json())
            .then(data => {
              if (data.success === false) {
                setLoading(false);
                // setError(data.message);
              } else {
                setLoading(false);
                setNeed_type(data.message);
                for (let i = 0; i < data.message.length; i++) {
                  const element = data.message[i];
                  if (data.message.length) {
                    newElement.push(element);
                  }

                  if (cards.some(d => element.need_type == d.header)) {
                    setNod(element.times_i_can_donate);
                  }

                  if (state.countries.length >= 1) {
                    let x = state.countries.find(d => d.title === 'Singapore');
                    if (x) {
                      setCountry(x);
                    }
                  }
                  if (state.states.length >= 1)
                    setMyState(state.states.find(d => d.title === 'Singapore'));
                  // if (unis) {
                  //   if (typeof unis.find === 'function') {
                  //     setUniversity(
                  //       unis.find(d => d.name === data.message[0].university)
                  //     );
                  //   }
                  // }
                  if (unis) {
                    setUniversity(data.message[0].university || 'Any');
                  }

                  uni = data.message[0].university;
                  // setName(data.message[0].name);
                  if (data.role === 'student') {
                    setStart_year(
                      yearsInNumber.find(
                        d => d.title === String(data.message[0].start_year)
                      )
                    );

                    setEnd_year(
                      yearsInNumber.find(
                        d => d.title === String(data.message[0].end_year)
                      )
                    );
                  }
                  setField(
                    fieldstudy.find(
                      // d => d.title === data.message[0].field_of_study
                      d => d.title === 'Any'
                    )
                  );
                  setName(data.message[0].name);
                  if (data.role === 'sponsor') {
                    setStudent_year(
                      year.find(d => d.title === data.message[0].student_year)
                    );
                  }
                  setState(prevState => {
                    const { university, student_id } = data.message[0];
                    const country = data.message[0].scountry;
                    const perfstate = data.message[0].state;
                    const startyear = data.message[0].start_year;
                    const endyear = data.message[0].end_year;
                    const field = data.message[0].field_of_study;
                    return {
                      ...prevState,
                      country,
                      state,
                      perfstate,
                      university,
                      student_id,
                      startyear,
                      endyear,
                      field,
                      name,
                    };
                  });
                }
              }
            })
            .catch(err => {
              setLoading(false);
              console.log('An error occurred while fetching data', err);
            });
        });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      fetch(process.env.REACT_APP_PROXY + '/data/university', requestOptions)
        .then(res => res.json())
        .then(data => {
          if (data.success === false) {
            setError(data.message);
          } else {
            if (uni !== '') {
              setUniversity(data.data.find(d => d.name === uni));
            }
            unis = data.data;
            setState(prevState => {
              const universities = data.data;

              return { ...prevState, universities };
            });
          }
        });
    }
  }

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    // fetch country
    fetch(process.env.REACT_APP_PROXY + '/data/countries', requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.success === false) {
          setError(data.message);
        } else {
          setState(prevState => {
            const countries = data.data;
            return { ...prevState, countries };
          });
        }
      });
    //fetch STATE
    fetch(process.env.REACT_APP_PROXY + '/data/states', requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.success === false) {
          setError(data.message);
        } else {
          setState(prevState => {
            const states = data.data;
            return { ...prevState, states };
          });
        }
      });
  }, []);

  // useEffect(() => {
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   };

  //   // Fetch countries
  //   fetch(process.env.REACT_APP_PROXY + '/data/countries', requestOptions)
  //     .then(res => res.json())
  //     .then(data => {
  //       if (data.success === false) {
  //         setError(data.message);
  //       } else {
  //         setState(prevState => ({
  //           ...prevState,
  //           countries: data.data,
  //         }));
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error fetching countries:', error);
  //       setError('Failed to fetch countries. Please try again.');
  //     });

  //   // Fetch states
  //   fetch(process.env.REACT_APP_PROXY + '/data/states', requestOptions)
  //     .then(res => res.json())
  //     .then(data => {
  //       if (data.success === false) {
  //         setError(data.message);
  //       } else {
  //         setState(prevState => ({
  //           ...prevState,
  //           states: data.data,
  //         }));
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error fetching states:', error);
  //       setError('Failed to fetch states. Please try again.');
  //     });
  // }, []);

  useEffect(() => {
    getall();
    setShouldRerender(prevState => !prevState);
  }, [state.countries, state.states]);



  const year = [
    // { title: 'Graduating this year' },
    // { title: 'Graduating next year' },
    { title: 'Freshman' },
    { title: 'Sophomore' },
    { title: 'Junior' },
    { title: 'Senior' },
    { title: 'Any year' },
  ];

  const yearsInNumber = [
    { title: '2017' },
    { title: '2018' },
    { title: '2019' },
    { title: '2020' },
    { title: '2021' },
    { title: '2022' },
    { title: '2023' },
    { title: '2024' },
    { title: '2025' },
    { title: '2026' },
    { title: '2027' },
  ];

  const fieldstudy = [
    { title: 'Science', country: 'United States' },
    { title: 'Math', country: 'United States' },
    { title: 'Engineering', country: 'Malaysia' },
    { title: 'Law', country: 'Singapore' },
    { title: 'Arts & Social Sciences', country: 'Singapore' },
    { title: 'Business', country: 'Singapore' },
    { title: 'Management & Communications', country: 'Singapore' },
    { title: 'Sports & Health', country: 'Singapore' },
    { title: 'Vocational', country: 'Singapore' },
    { title: 'Any' },
  ];

  const needtype = [
    { title: 'Tuition' },
    { title: 'Housing' },
    { title: 'Meal Plan' },
    { title: 'Books' },
    { title: 'Health Insurance' },
    { title: 'AirTicket' },
    { title: 'Laptop' },
    { title: 'Misc' },
    { title: 'Any' },
  ];

  const cards = [
    {
      no: 1,
      calName: 'books',
      header: 'Books',
      detail: 'If you are a book worm raise  a request here.',
      img: books,
      isShow: false,
    },
    ,
    // {
    //   no: 2,
    //   calName: 'laptop',
    //   header: 'Laptop',
    //   detail: 'If you need support for buying a laptop, apply here.',
    //   img: laptop,
    //   isShow: false,
    // },
    // {
    //   no: 3,
    //   calName: 'certification',
    //   header: 'Online Certification',
    //   detail:
    //     'If you need support in paying for online certification courses, apply here',
    //   img: certification,
    //   isShow: false,
    // },
    // {
    //   no: 4,
    //   calName: 'insurance',
    //   header: 'Health Insurance',
    //   detail: 'If you need support for buying health insurance, apply here.',
    //   img: insurance,
    //   isShow: false,
    // },
    // {
    //   no: 5,
    //   calName: 'housing',
    //   header: 'Housing',
    //   detail: 'If you need support to pay for accomodation, apply here',
    //   img: housing,
    //   isShow: false,
    // }
    //{
    //   no: 6,
    //   calName: 'food_voucher',
    //   header: 'Food and Beverage',
    //   detail: 'For Sugar, Spice and Everything Nice! ',
    //   img: mealPlan,
    //   isShow: false,
    // },
    {
      no: 7,
      calName: 'tuition',
      header: 'Tuition',
      detail: ' Is there a small gap towards tuition ?',
      img: school,
      isShow: false,
    },
    ,
    // {
    //   no: 8,
    //   calName: 'miles',
    //   header: 'Air miles',
    //   detail:
    //     'If you need support in travelling home from overseas university, apply here.',
    //   img: airmiles,
    //   isShow: false,
    // },
    // {
    //   no: 9,
    //   calName: 'accessibility',
    //   header: 'Accessibility Needs',
    //   detail: 'Need support in other areas for education, apply here.',
    //   img: accessibility,
    //   isShow: false,
    // },
    // {
    //   no: 10,
    //   calName: 'counseling',
    //   header: 'Counseling Services',
    //   detail: 'If you need support in other areas for education, apply here.',
    //   img: counsel,
    //   isShow: false,
    // },
    // {
    //   no: 11,
    //   calName: 'others',
    //   header: 'Others',
    //   detail: 'If you need support in other areas for education, apply here.',
    //   img: others,
    //   isShow: false,
    // },
    // {
    //   no: 12,
    //   calName: 'mrt',
    //   header: 'MRT',
    //   detail: 'Need to get to college!, apply here.',
    //   img: mrt,
    //   isShow: false,
    // }
    {
      no: 13,
      calName: 'pair_of_glasses',
      header: 'Pair Of Glasses',
      detail: 'Please raise a request if you need pair of glasses.',
      img: glasses,
      isShow: false,
    },
  ];

  const needrange = [{ title: 'low' }, { title: 'mid' }, { title: 'high' }];

  // Connection string to the database through firebase and user token ID;
  // SQL statement to submit preferences
  let data = false;
  const handleAmount = (e, forVal) => {
    e.persist();
    const { value, id } = e.target;
    need_type.map(n => {
      if (n.need_type === forVal) {
        return (n.donate_max_amount = Number(value));
      } else {
        return n;
      }
    });

    setNeed_type(need_type);

    if (donateMax.length != 0) {
      donateMax.map(item => {
        if (item.id == id) {
          data = true;
          item.amount = Number(value);
        }
      });
    } else {
      donateMax.push({ id: id, amount: Number(value) });
      data = true;
    }
    if (!data) {
      donateMax.push({ id: id, amount: Number(value) });
    }
    setDonateMax([...donateMax]);
  };

  let data2 = false;
  const handleNod = (e, forVal) => {
    e.persist();
    const { value, id } = e.target;
    need_type.map(n => {
      if (n.need_type === forVal) {
        return (n.times_i_can_donate = Number(value));
      } else {
        return n;
      }
    });

    setNeed_type(need_type);
    if (times_i_can_donate.length != 0) {
    } else {
      times_i_can_donate.push({ id: id, nod: value });
      data2 = true;
    }
    if (!data2) {
      times_i_can_donate.push({ id: id, nod: value });
    }
    setTimes_i_can_donate([...times_i_can_donate]);
  };

  let data3 = false;
  // const handleOnTap = e => {
  //   var tempdata = [];
  //   const { checked, value, id } = e.target;
  //   if (checked) {
  //     if (!data3) {
  //       tempdata.push({ id: id, need_type: value });
  //     }
  //     var x = need_type.concat(tempdata);
  //     setNeed_type(x);
  //   } else {
  //     need_type.map(item => {
  //       if (item.need_type == value) {
  //         var index = need_type.indexOf(item);
  //         need_type.splice(index, 1);
  //         setNeed_type([...need_type]);
  //       }
  //     });
  //   }
  // };

  const handleOnTap = header => {
    // Check if the option is already selected
    const isAlreadySelected = need_type.some(e => e.need_type === header);

    if (!isAlreadySelected) {
      // If not selected, add it to the array
      const newData = {
        need_type: header,
        donate_max_amount: '',
        times_i_can_donate: '',
      };
      setNeed_type([...need_type, newData]);
    } else {
      // If selected, remove it from the array
      const updatedList = need_type.filter(e => e.need_type !== header);
      setNeed_type(updatedList);
    }
  };

  // const getValue = (need_type, header) => {
  //   if(need_type.some(el => el.need_type == header)){
  //     if(header == 'Books'){
  //       return 100
  //     }else{
  //       if(need_type.filter(n => n.need_type == header)){
  //         return need_type[0]?.donate_max_amount
  //       }else{
  //         return false
  //       }
  //     }
  //   }else{

  //   }
  // };

  const submitPref = () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(function (idToken) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
          body: JSON.stringify({
            state: 'Singapore',
            country: 'Singapore',
            // university: university,
            university: 'Any',
            start_date: 2021 || start_year.title,
            end_date: 2024 || end_year.title,
            // field_of_study: field.title,
            field_of_study: 'Any',
            name: name,
            amount: donateMax,
            need_range: needrange.title,
            student_id: state.student_id,
            student_year: student_year.title,
            need_type: need_type,
          }),
        };

        if (name !== '') {
          setLoading(true);
          fetch(process.env.REACT_APP_PROXY + '/user/pref', requestOptions)
            .then(res => res.json())
            .then(data => {
              if (data.success === false) {
                setLoading(false);
                setError('Please enter the details');
                toast.error('Please enter the details');
              } else {
                setLoading(false);
                dispatch(updateUserName(name));
                toast.success('Details Updated Successfully', {
                  autoClose: 2000,
                });
                props.setPage('dashboard');
                props.setOpen(false);
                props.handleClose();
              }
            })
            .catch(function (error) {
              setLoading(false);
              console.log('An error occurred while fetching data', error);
            });
        } else {
          props.setOpen(true);
          setError('Please enter the name');
        }
      });
  };

  var totalAmount;
  var totalTime;

  return (
    <>
      {loading && <Spinner />}
      <div className="">
        <div className="m-3 my-4">
          {!props.open ? (
            <h5>
              {props.open}
              <span
                className="d-flex justify-content-center my-5 "
                style={{ fontWeight: 600, fontSize: '2rem' }}
              >
                Create your Preference
              </span>
              {/* {role === 'student'
              ? 'My Profile '
                ? role === 'sponsor'
                : 'Enter your Details'
              : 'Enter your Details'} */}
            </h5>
          ) : (
            ''
          )}

          <div className={classes.space}>
            {/* <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ margin: '0px auto' }}
          >
            <Avatar className={classes.root.large} />
          </Grid> */}
            {/* <div className={classes.topAdminContainer}>
              <div className={classes.adminImgContainer}>
                <img
                  src={
                    role === 'student'
                      ? student
                      : role === 'sponsor'
                      ? sponsor
                      : organization
                  }
                  alt=""
                  width={100}
                />
                 <Avatar
                alt="Profile Picture"
                style={{
                  backgroundColor: 'gray',
                  color: '#fff',
                  width: 40,
                  height: 40,
                  fontSize: '1.2rem',
                  marginBottom: '5px', 
                }}
              >
                {getInitials(name)} 
              </Avatar>
              </div>
            </div> */}
            <div className="form-bottom ">
              {role === 'sponsor' ? (
                // <div className="left">
                //   <TextField
                //     disabled={false}
                //     value={name}
                //     onChange={e => {
                //       e.persist();
                //       setName(e.target.value);
                //       setState(prevState => {
                //         const name = e.target.value;
                //         return { ...prevState, name };
                //       });
                //     }}
                //     style={{ width: '100%', marginTop: 20 }}
                //     label="Name"
                //     variant="outlined"
                //     InputProps={{
                //       className: 'disabled_text',
                //     }}
                //     className={classes.input}
                //   />
                // </div>
                <div className="form-group">
                  <label className={classes.formGroupLabel} htmlFor="">
                    Name
                  </label>
                  <input
                    className={classes.formGroupInput}
                    type="text"
                    onChange={e => {
                      e.persist();
                      setName(e.target.value);
                      setState(prevState => {
                        const name = e.target.value;
                        return { ...prevState, name };
                      });
                    }}
                    placeholder="Enter your name"
                    value={name}
                  />
                  {props.open && (
                    <div className="form-group mt-3">
                      <label className={classes.formGroupLabel} htmlFor="">
                        Email
                      </label>
                      <input
                        className={classes.formGroupInput}
                        type="email"
                        disabled
                        value={props.email}
                      />
                    </div>
                  )}
                </div>
              ) : (
                //   <TextField
                //     disabled={false}
                //     value={name}
                //     onChange={e => {
                //       e.persist();
                //       setName(e.target.value);
                //       setState(prevState => {
                //         const name = e.target.value;
                //         return { ...prevState, name };
                //       });
                //     }}
                //     style={{ width: '100%', marginTop: 20 }}
                //     label="Name"
                //     variant="outlined"
                //     InputProps={{
                //       className: 'disabled_text',
                //     }}
                //     className={classes.input}
                //   />
                // )}
                // <div style={{ width: '100%', padding: '8px 4px 4px 4px' }}>
                //   <label style={{ color: 'red' }}>{error}</label>
                // </div>
                <>
                  <div className="form-group">
                    <label className={classes.formGroupLabel} htmlFor="">
                      <span className="fs-5">Name</span>
                    </label>
                    <input
                      className={classes.formGroupInput}
                      type="text"
                      onChange={e => {
                        e.persist();
                        setName(e.target.value);
                        setState(prevState => {
                          const name = e.target.value;
                          return { ...prevState, name };
                        });
                      }}
                      placeholder="Enter your name"
                      value={name}
                    />
                  </div>{' '}
                  {props.open && (
                    <div className="form-group mt-3">
                      <label className={classes.formGroupLabel} htmlFor="">
                        Email
                      </label>
                      <input
                        className={classes.formGroupInput}
                        type="email"
                        disabled
                        value={props.email}
                      />
                    </div>
                  )}
                </>
              )}
              {/* <Typography variant="subtitle1" color="textSecondary">
            Details of Sponsorship
          </Typography> */}

              {/* <AutocompleteOverwrite
          id="combo-box-demo"
          options={
            role === 'student' && state.countries.length >= 1
              ? state.countries.filter(w => w.title !== 'Any')
              : state.countries
          }
          getOptionLabel={option => option.title}
          style={{
            width: '100%',
            marginTop: 20,
            color: 'rgba(0, 0, 0, 1) !important',
            opacity: '1',
          }}
          onInputChange={e => {
            if (e && e.target.value !== '') {
            }
          }}
          onChange={(e, v, r) => {
            if (v != null) {
              setCountry(state.countries.find(d => d.title === v.title));
              setMyState('');
              setState(prevState => {
                const country = v.title;
                return { ...prevState, country };
              });
            }
          }}
          value={country}
          noOptionsText="Press confirm"
          renderInput={params => (
            <TextField
              {...params}
              label="Country"
              variant="outlined"
              className={classes.input}
            />
          )}
        />
        <AutocompleteOverwrite
          id="combo-box-demo"
          options={
            state.states.length > 1
              ? state.states.filter(w => {
                  if (w && country) {
                    return (
                      w.title !== 'Any' &&
                      (country.title !== '' ? country.title === w.country : 1)
                    );
                  }
                })
              : state.states
          }
          value={mystate}
          getOptionLabel={option => option.title}
          style={{ width: '100%', marginTop: 20 }}
          onInputChange={e => {
            if (e && e.target.value !== '') {
            }
          }}
          onChange={(e, v, r) => {
            if (v != null) {
              setMyState(state.states.find(d => d.title === v.title));
              setState(prevState => {
                const state = v.title;

                return { ...prevState, state };
              });
            }
          }}
          noOptionsText="Press confirm"
          renderInput={params => (
            <TextField
              {...params}
              label="State"
              variant="outlined"
              className={classes.input}
            />
          )}
        /> */}

              {/* <AutocompleteOverwrite
          id="combo-box-demo"
          options={
            role === 'student'
              ? state.universities.filter(
                  w =>
                    w.name !== 'Any' &&
                    (mystate !== '' ? mystate.title === w.state : 1) &&
                    (country !== '' ? country.title === w.country : 1)
                )
              : state.universities.filter(
                  w =>
                    w.name === 'Any' ||
                    ((mystate !== '' ? mystate.title === w.state : 1) &&
                      (country !== '' ? country.title === w.country : 1))
                )
          }
          value={university}
          getOptionLabel={option => option.name}
          style={{ width: '100%', marginTop: 20 }}
          onInputChange={e => {
            if (e && e.target.value !== '') {
              // Handle input change if needed
            }
          }}
          onChange={(e, v, r) => {
            if (v != null) {
              setUniversity(
                v.name ? state.universities.find(d => d.name === v.name) : 'Any'
              );
              setState(prevState => {
                let university;
                if (v.name) {
                  university = v.name;
                } else {
                  university = 'Any';
                }
                return { ...prevState, university };
              });
            }
          }}
          noOptionsText="Press confirm"
          renderInput={params => (
            <TextField
              {...params}
              label="University"
              variant="outlined"
              className={classes.input}
            />
          )}
        /> */}

              {/* <p>University Name</p> */}
              {/* <TextField
            variant="outlined"
            className={classes.input}
            value={university}
            fullWidth
            onChange={e => setUniversity(e.target.value)}
          /> */}

              {role === 'sponsor' ? (
                <AutocompleteOverwrite
                  id="combo-box-demo"
                  options={year}
                  value={student_year}
                  disabled={false}
                  getOptionLabel={option => option.title}
                  style={{ width: '100%', marginTop: 20 }}
                  onInputChange={e => {
                    if (e && e.target.value !== '') {
                    }
                  }}
                  onChange={(e, v, r) => {
                    if (v != null) {
                      setStudent_year(
                        v.title
                          ? year.find(d => d.title === v.title)
                          : 'Any year'
                      );
                      setState(prevState => {
                        let student_year;
                        if (v.title) {
                          student_year = v.title;
                        } else {
                          student_year = 'Any year';
                        }
                        return { ...prevState, student_year };
                      });
                    }
                  }}
                  noOptionsText="Press confirm"
                  renderInput={
                    params => ''
                    // <TextField
                    //   {...params}
                    //   label="Student's Current year"
                    //   variant="outlined"
                    //   className={classes.input}
                    // />
                  }
                />
              ) : (
                <div>
                  {/* <TextField
                  label="Student id "
                  className={classes.input}
                  value={state.student_id}
                  onChange={e => {
                    e.persist();
                    setState(prevState => {
                      const student_id = e.target.value;
                      return { ...prevState, student_id };
                    });
                  }}
                  style={{
                    width: '100%',
                    marginTop: 10,
                  }}
                  variant="outlined"
                  InputProps={{
                    className: 'disabled_text',
                  }}
                /> */}

                  <div className="form-group">
                    <label className={classes.formGroupLabel} htmlFor="">
                      Student id
                    </label>
                    <input
                      className={classes.formGroupInput}
                      type="text"
                      onChange={e => {
                        e.persist();
                        setState(prevState => {
                          const student_id = e.target.value;
                          return { ...prevState, student_id };
                        });
                      }}
                      placeholder="Enter your Id"
                      value={state.student_id}
                    />
                  </div>
                  {/* <AutocompleteOverwrite
              id="combo-box-demo"
              options={yearsInNumber.filter(
                year =>
                  year.title <=
                  (end_year.title === '' ? '2024' : end_year.title)
              )}
              value={start_year}
              getOptionLabel={option => option.title}
              style={{ width: '100%', marginTop: 20 }}
              onInputChange={e => {
                if (e && e.target.value !== '') {
                }
              }}
              onChange={(e, v, r) => {
                if (v != null) {
                  setStart_year(yearsInNumber.find(d => d.title === v.title));
                  setState(prevState => {
                    const startyear = v.title;

                    return { ...prevState, startyear };
                  });
                }
              }}
              noOptionsText="Press confirm"
              renderInput={params => (
                <TextField
                  {...params}
                  label="Starting in year"
                  variant="outlined"
                  className={classes.input}
                />
              )}
            />
            <AutocompleteOverwrite
              id="combo-box-demo"
              disabled={false}
              value={end_year}
              options={yearsInNumber.filter(
                year => year.title >= start_year.title
              )}
              getOptionLabel={option => option.title}
              style={{ width: '100%', marginTop: 20 }}
              onInputChange={e => {
                if (e && e.target.value !== '') {
                }
              }}
              onChange={(e, v, r) => {
                if (v != null) {
                  setEnd_year(yearsInNumber.find(d => d.title === v.title));
                  setState(prevState => {
                    const endyear = v.title;

                    return { ...prevState, endyear };
                  });
                }
              }}
              noOptionsText="Press confirm"
              renderInput={params => (
                <TextField
                  {...params}
                  label="Completing in year"
                  variant="outlined"
                  className={classes.input}
                />
              )}
            /> */}
                </div>
              )}
            </div>
            {/* <AutocompleteOverwrite
            id="combo-box-demo"
            options={fieldstudy}
            value={field}
            disabled={false}
            getOptionLabel={option => option.title}
            style={{
              width: '100%',
              // marginTop: 20  ,
              marginBottom: 20,
            }}
            onInputChange={e => {
              if (e && e.target.value !== '') {
              }
            }}
            onChange={(e, v, r) => {
              if (v != null) {
                setField(
                  v.title ? fieldstudy.find(d => d.title === v.title) : 'Any'
                );
                setState(prevState => {
                  let field;
                  if (v.title) {
                    field = v.title;
                  } else {
                    field = 'Any';
                  }
                  return { ...prevState, field };
                });
              }
            }}
            noOptionsText="Press confirm"
            renderInput={params => (
              <TextField
                {...params}
                label="Field Of Study"
                variant="outlined"
                className={classes.input}
              />
            )}
          /> */}
            {role === 'sponsor' ? (
              // <div
              //   style={{
              //     marginTop: 20,
              //   }}
              // >
              //   <Typography variant="subtitle1" color="textSecondary">
              //    <span>Sponsorship categories and limit</span>
              //     <Tooltip
              //       disableFocusListener
              //       disableTouchListener
              //       style={{ color: 'black', fontSize: '18px' }}
              //       title={
              //         'Choose the student micro needs would you like to support. Enter the maximum currency amount per sponsorship and the number of such requests you will support.'
              //       }
              //     >
              //       <IconButton>
              //         <i class="fa fa-info-circle" aria-hidden="true"></i>
              //       </IconButton>
              //     </Tooltip>
              //   </Typography>
              //   <Divider />
              //   {/* {JSON.stringify(newElement)} */}
              //   <Table className={classes.blockboxDesk} responsive="md">
              //     {cards.map((card, index) =>
              //       card.no ? (
              //         <tr key={index} style={{ marginTop: '5px' }}>
              //           <td style={{ width: '10%' }}>
              //             <Checkbox
              //               key={index}
              //               style={{ marginTop: '10px' }}
              //               checked={
              //                 need_type.some(e => card.header === e.need_type)
              //                   ? true
              //                   : false
              //               }
              //               id={card.no}
              //               value={card.header}
              //               onChange={handleOnTap}
              //             />
              //           </td>
              //           <td style={{ width: '15%' }}>
              //             <img
              //               src={card.img}
              //               style={{
              //                 width: '50%',
              //                 marginTop: '30px',
              //               }}
              //             />
              //           </td>
              //           <td style={{ width: '20%' }}>
              //             <Typography
              //               gutterBottom
              //               variant="h6"
              //               component="h5"
              //               style={{ fontSize: '15px' }}
              //             >
              //               {card.header === 'Pair Of Glasses'
              //                 ? 'Glasses'
              //                 : card.header}
              //             </Typography>
              //           </td>
              //           <td style={{ width: '30%', marginTop: '10px' }}>
              //             <TextField
              //               style={{ marginTop: '20px' }}
              //               disabled={
              //                 need_type.some(el => el.need_type == card.header)
              //                   ? false
              //                   : true
              //               }
              //               variant="outlined"
              //               type="text"
              //               value={
              //                 need_type.some(el => el.need_type == card.header)
              //                   ? need_type.filter(n =>
              //                       n.need_type === card.header ? n : false
              //                     )[0]?.donate_max_amount
              //                   : ''
              //               }
              //               id={card.header}
              //               label={
              //                 need_type.some(el => el.need_type == card.header)
              //                   ? 'S$  Amount'
              //                   : 'S$  Amount'
              //               }
              //               onChange={e => handleAmount(e, card.header)}
              //             ></TextField>
              //           </td>
              //           <td style={{ width: '35%' }}>
              //             <TextField
              //               disabled={
              //                 need_type.some(el => el.need_type == card.header)
              //                   ? false
              //                   : true
              //               }
              //               style={{ marginTop: '20px' }}
              //               variant="outlined"
              //               value={
              //                 need_type.some(el => el.need_type == card.header)
              //                   ? need_type.filter(n =>
              //                       n.need_type === card.header ? n : false
              //                     )[0]?.times_i_can_donate
              //                   : ''
              //               }
              //               type="text"
              //               label={
              //                 need_type.some(el => el.need_type == card.header)
              //                   ? 'No. of requests'
              //                   : 'No. of requests'
              //               }
              //               id={card.header}
              //               onChange={e => handleNod(e, card.header)}
              //             ></TextField>
              //           </td>
              //         </tr>
              //       ) : (
              //         ''
              //       )
              //     )}
              //     {need_type.length > 0 ? (
              //       <>
              //         <br />
              //         <tr>
              //           <td
              //             style={{
              //               marginTop: '10px',
              //               fontSize: '20px',
              //               fontWeight: 'bold',
              //               // textAlign: 'center',
              //             }}
              //             colSpan={3}
              //           >
              //             Total
              //           </td>
              //           <td style={{ fontSize: '18px' }}>
              //              {need_type.reduce(
              //               (total, data) =>
              //                 (totalAmount =
              //                   total +
              //                   parseInt(
              //                     data.donate_max_amount *
              //                       data.times_i_can_donate,
              //                     10
              //                   )),
              //               0
              //             )
              //               ? `Total amount : ${totalAmount}`
              //               : 0}
              //           </td>
              //           <td style={{ fontSize: '18px' }}>
              //             {need_type.reduce(
              //               (total, data) =>
              //                 (totalTime =
              //                   total + parseInt(data.times_i_can_donate, 10)),
              //               0
              //             )
              //               ? `Total Request : ${totalTime}`
              //               : 0}
              //           </td>
              //         </tr>
              //       </>
              //     ) : (
              //       ''
              //     )}
              //   </Table>
              //   <br />
              //   <Grid container spacing={4} className={classes.blockboxMb}>
              //     {cards.map(card =>
              //       card.no ? (
              //         <Grid item key={card.no} xs={12} sm={6} md={4} lg={4}>
              //           <Card style={{ marginTop: '10px' }}>
              //             <Checkbox
              //               // defaultChecked={donateMax ? true : false}
              //               checked={
              //                 need_type.some(e => card.header === e.need_type)
              //                   ? true
              //                   : false
              //               }
              //               id={card.header}
              //               value={card.header}
              //               onChange={handleOnTap}
              //             />
              //             <CardMedia
              //               style={{ width: '50%' }}
              //               image={card.img}
              //               title="Image title"
              //               component="img"
              //             />
              //             <CardContent style={{ marginTop: '-55px' }}>
              //               <Typography
              //                 gutterBottom
              //                 variant="h6"
              //                 component="h5"
              //                 style={{ fontSize: '15px', marginTop: '20px' }}
              //               >
              //                 {card.header}
              //               </Typography>
              //               <TextField
              //                 disabled={
              //                   need_type.some(el => el.need_type == card.header)
              //                     ? false
              //                     : true
              //                 }
              //                 variant="outlined"
              //                 type="text"
              //                 value={
              //                   need_type.filter(n =>
              //                     n.need_type === card.header ? n : false
              //                   )[0]?.donate_max_amount
              //                 }
              //                 id={card.header}
              //                 label={
              //                   need_type.some(el => el.need_type == card.header)
              //                     ? ''
              //                     : 'Amount'
              //                 }
              //                 onChange={e => handleAmount(e, card.header)}
              //               ></TextField>
              //               <TextField
              //                 disabled={
              //                   need_type.some(el => el.need_type == card.header)
              //                     ? false
              //                     : true
              //                 }
              //                 style={{ marginTop: '20px' }}
              //                 variant="outlined"
              //                 value={
              //                   need_type.filter(n =>
              //                     n.need_type === card.header ? n : false
              //                   )[0]?.times_i_can_donate
              //                 }
              //                 type="text"
              //                 label={
              //                   need_type.some(el => el.need_type == card.header)
              //                     ? ''
              //                     : 'No. of requests'
              //                 }
              //                 id={card.header}
              //                 onChange={e => handleNod(e, card.header)}
              //               ></TextField>
              //             </CardContent>
              //           </Card>
              //         </Grid>
              //       ) : (
              //         ''
              //       )
              //     )}
              //     <br />
              //   </Grid>
              // </div>
              <div className="admin-modal2">
                <div className="form-bottom">
                  <form>
                    <div className="categories">
                      <span className="fs-5 my-2">
                        Sponsorship categories and limit
                      </span>
                      <div className="table-bottom table-sec">
                        <div className="table-box">
                          <div className="scroll-table-box">
                            <table width="100%" cellPadding={0} cellSpacing={0}>
                              <tbody>
                                {cards.map(
                                  (card, index) =>
                                    card.no && (
                                      <tr key={index}>
                                        <td>
                                          <label className="checkbox-box">
                                            <input
                                              type="checkbox"
                                              checked={need_type.some(
                                                e => card.header === e.need_type
                                              )}
                                              onChange={() =>
                                                handleOnTap(card.header)
                                              }
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </td>
                                        <td>
                                          <div className="images">
                                            <img
                                              src={card.img}
                                              alt={card.header}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <span className="fs-5 my-2">
                                            {card.header === 'Pair Of Glasses'
                                              ? 'Glasses'
                                              : card.header}
                                          </span>
                                        </td>
                                        <td>
                                          <div className="form-group">
                                            <label>S$ Amount</label>
                                            <input
                                              type="text"
                                              value={
                                                need_type.some(
                                                  el =>
                                                    el.need_type === card.header
                                                )
                                                  ? need_type.find(
                                                      el =>
                                                        el.need_type ===
                                                        card.header
                                                    ).donate_max_amount
                                                  : ''
                                              }
                                              onChange={e =>
                                                handleAmount(e, card.header)
                                              }
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-group">
                                            <label>No. of requests</label>
                                            <input
                                              type="text"
                                              value={
                                                need_type.some(
                                                  el =>
                                                    el.need_type === card.header
                                                )
                                                  ? need_type.find(
                                                      el =>
                                                        el.need_type ===
                                                        card.header
                                                    ).times_i_can_donate
                                                  : ''
                                              }
                                              onChange={e =>
                                                handleNod(e, card.header)
                                              }
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                )}
                                <tr>
                                  <td colSpan={3}>
                                    <span className="fs-5">Total</span>
                                  </td>
                                  <td>
                                    <div className="form-group text-right">
                                      <label>
                                        <span className="fs-5">
                                          Total amount:{' '}
                                          {need_type.reduce(
                                            (total, data) =>
                                              total +
                                              parseInt(
                                                data.donate_max_amount *
                                                  data.times_i_can_donate,
                                                10
                                              ),
                                            0
                                          )}
                                        </span>
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group text-right">
                                      <label>
                                        <span className="fs-5">
                                          Total request:{' '}
                                          {need_type.reduce(
                                            (total, data) =>
                                              total +
                                              parseInt(
                                                data.times_i_can_donate,
                                                10
                                              ),
                                            0
                                          )}
                                        </span>
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              ''
            )}
            {!props.disabled ? (
              <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
                <Button
                  onClick={submitPref}
                  color="primary"
                  disabled={
                    role === 'sponsor'
                      ? name.length < 1 ||
                        country.title.length < 1 ||
                        need_type.length < 1 ||
                        donateMax.length < 1 ||
                        times_i_can_donate.length < 1 ||
                        university.length < 1 ||
                        field.title.length < 1
                      : role === 'student'
                      ? name.length < 1 ||
                        country.title.length < 1 ||
                        state.states.length < 1 ||
                        !mystate.title ||
                        start_year.title.length < 1 ||
                        end_year.title.length < 1 ||
                        field.title.length < 1 ||
                        state.student_id.length < 1 ||
                        university.length < 1
                      : true
                  }
                >
                  Submit
                </Button>
              </Box>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
});
