import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import Spinner from '../utils/spinner';
import '../css/table.css'

function Table({
  columns,
  rows,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  renderRow,
  loading,
  role,
  emptyMessageGenerator, // Optional prop for generating custom messages
}) {
  // Generate a message for when no rows are found
  const getEmptyMessage = () => {
    if (emptyMessageGenerator) {
      return emptyMessageGenerator(role);
    }
    switch (role) {
      case 'sponsor':
        return 'No Student Request Matched with your Preference';
      case 'student':
        return `You haven't filled out any applications yet.`;
      default:
        return 'No Record found';
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="table-box">
          <div className="scroll-table-box">
            <table width="100%" cellPadding={0} cellSpacing={0} role="table">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index} scope="col" style={{ textAlign: 'center' }}>
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.length > 0 ? (
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(rowData => (
                      <>
                        <tr key={rowData.id}>{renderRow(rowData)}</tr>
                      </>
                    ))
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="no-record">
                      {getEmptyMessage()}
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan={columns.length}
                    style={{ borderTop: '1px solid' }}
                  >
                    <TablePagination
                      rowsPerPageOptions={[3, 5, 10]}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                      }}
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default Table;
