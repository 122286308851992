import React, { useState } from 'react';
import '../css/tablefilter.css';

const TableFilter = ({ setCategory, setQuery, categories }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchCategory, setSearchCategory] = useState(categories[0]);
  const [searchQuery, setSearchQuery] = useState('');

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const handleCategorySelect = category => {
    setSearchCategory(category);
    setCategory(category); // Ensure parent state updates
    setDropdownVisible(false);
    setQuery(searchQuery);
  };

  // Handle input changes and trigger search instantly
  const handleInputChange = e => {
    const query = e.target.value;
    setSearchQuery(query);
    setQuery(query); // Ensure filtering updates in real-time
  };

  // Clear search field and restore all data
  const handleClearSearch = () => {
    setSearchQuery('');
    setQuery(''); // Reset filter in parent state
  };

  return (
    <div className="form-container">
      <form onSubmit={e => e.preventDefault()}>
        <div className="form-groupForAddStudnet">
          {/* Category Dropdown */}
          <button
            type="button"
            className="dropdown-button"
            onClick={toggleDropdown}
          >
            {searchCategory}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
              width="10"
              height="10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1l4 4 4-4"
              />
            </svg>
          </button>

          {/* Dropdown List */}
          {dropdownVisible && (
            <div className="dropdown">
              <ul>
                {categories.map(category => (
                  <li
                    key={category}
                    onClick={() => handleCategorySelect(category)}
                  >
                    {category}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Search Input */}
          <input
            type="search"
            className="search-input"
            placeholder={`Search by ${searchCategory}`}
            value={searchQuery}
            onChange={handleInputChange}
            required
          />

          {/* Dynamic Search / Clear Button */}
          <button
            type="button"
            className="submit-button"
            onClick={searchQuery ? handleClearSearch : null}
          >
            {searchQuery ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
                width="16"
                height="16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 5l10 10M5 15L15 5"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
                width="16"
                height="16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 19l-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TableFilter;
