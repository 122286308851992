export const setOrgDetails = (students) => ({
  type: 'SET_ORG_DETAILS',
  payload: students,
});

export const setAllStudents = (details) => ({
  type: 'SET_ALL_STUDENTS',
  payload: details,
});

export const setApplicationsCount = (count) => ({
  type: 'SET_APPLICATION_COUNT',
  payload: count,
});