import React, { useState } from 'react';
import '../css/sendmessage.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { TypeAnimation } from 'react-type-animation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const SendMail = props => {
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const { onSend, setOpen } = props;

  const MAX_MESSAGE_LENGTH = 500;

  const handleClearMessage = () => {
    setMessage('');
    setMessageError('');
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (message.length<50) {
      setMessageError('At least 50 char message is required');
      return;
    }

    if (message.length > MAX_MESSAGE_LENGTH) {
      setMessageError(
        `Message exceeds the limit of ${MAX_MESSAGE_LENGTH} characters.`
      );
      return;
    }

    onSend(message);
    setOpen(false);
  };

  const handleMessageChange = content => {
    if (content.length <= MAX_MESSAGE_LENGTH) {
      setMessage(content);
      setMessageError('');
    } else {
      setMessageError(
        `Message exceeds the limit of ${MAX_MESSAGE_LENGTH} characters.`
      );
    }
  };

  return (
    <>
      <div className="container">
        <div className="message-container">
          <div className="message-header pb-3">
            <TypeAnimation
              preRenderFirstString={true}
              className="h5 mb-0 px-3 font-weight-bold"
              sequence={[
                500,
                'Express Your Gratitude: Send a Heartfelt Message',
                1000,
                'Express Your Gratitude: Send a Thoughtful Note',
                1000,
                'Express Your Gratitude: Send a Personal Message',
                1000,
              ]}
              speed={50}
              repeat={Infinity}
            />
            <div>
              <button
                className="btn btn-light btn-sm"
                onClick={() => setOpen(false)}
              >
                <CancelIcon style={{ color: 'gray' }} />
              </button>
            </div>
          </div>
          <div className="message-body">
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message to sponsor :
              </label>
              {/* <textarea
                className="form-control"
                id="message"
                rows={12}
                value={message}
                placeholder={
                  "Dear [Sponsor],\n\nThank you so much for your support! Your help means a lot to me as I continue my studies. I'm excited about the opportunities ahead, and I couldn't do it without you.\n\nBest regards,\n[Your Name]"
                }
                onChange={handleMessageChange}
                style={{ resize: 'none' }}
              /> */}
              <ReactQuill
                theme="snow"
                value={message}
                placeholder={
                  "Dear [Sponsor],\n\nThank you so much for your support! Your help means a lot to me as I continue my studies. I'm excited about the opportunities ahead, and I couldn't do it without you.\n\nBest regards,\n[Your Name]"
                }
                onChange={handleMessageChange}
                className="custom-editor"
              />
              <small className="form-text text-muted">
                {message.length}/{MAX_MESSAGE_LENGTH} characters
              </small>
              {messageError && (
                <div className="text-danger mt-1">{messageError}</div>
              )}
            </div>
          </div>
          <div className="message-footer mx-3">
            <button
              className="btn"
              onClick={handleClearMessage}
              style={{ backgroundColor: '#77000D', color: 'white' }}
            >
              Clear message
            </button>
            <button
              className="btn"
              style={{ backgroundColor: '#77000D', color: 'white' }}
              onClick={handleSubmit}
            >
              Send message
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMail;
