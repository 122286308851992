const getInitials = name => {
  const names = name.split(' ');
  const initials = names.map(n => n[0])
  const ProfilePicName = initials[0] + initials[1]; 
  return ProfilePicName.toUpperCase();
};

const getShortName = (name) => {
  //maximum length
  const maxLength = 14;
  // If the name is longer than the maximum length, truncate it
  if (name.length > maxLength) {
    return name.slice(0, maxLength) + '...';
  } else {
    return name;
  }
};

export {getShortName, getInitials}