// Functionality of this code : This page is for all Students added by org
// Input : None
// Output : All students added by org
// Written by: Akash Shah

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import checkAuth from '../../utils/checkAuth';
import Loading from '../../common/Loader/Loader';
import decrypt from '../../utils/DecryptData';
import { Chip } from '@mui/material';
import Table from '../../common/Table';
import TableFilter from '../../common/TableFilter';
import useFilter from '../../hooks/useFilter';

const ViewAllStudents = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requestPage, setRequestPage] = useState(0);
  const [requestRowsPerPage, setRequestRowsPerPage] = useState(5);
  const [totalStudents, SetTotalStudents] = useState(0);
  const categories = ['University', 'Codes', 'Student Email'];

  useEffect(async () => {
    const { idToken } = await checkAuth();
    axios
      .get(`${process.env.REACT_APP_PROXY}/user/org/students`, {
        headers: {
          'Content-Type': 'application/json',
          auth: idToken,
        },
      })
      .then(response => {
        // Decrypt codes before setting the state
        const decryptedStudents = response.data.data.map(student => {
          const decryptedEmail = decrypt(student.email);
          const maskedEmail = decryptedEmail.replace(/^(.{2}).*@/, '$1XXXX');
          return {
            ...student,
            codes: decrypt(student.codes),
            email: maskedEmail,
          };
        });
       
        SetTotalStudents(response.data.totalStudents);
        setStudents(decryptedStudents);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching students:', error);
        setLoading(false);
      });
  }, []);

  // Use the custom filtering hook
  const { filteredData, setCategory, setQuery } = useFilter(
    students,
    categories
  );

  // Columns for Student Info from Organization
  const requestStatusColumns = [
    'University',
    'Codes',
    'Student Email',
    'Status',
  ];

  // Function to render a row
  const renderRequestStatusRow = rowData => {
    return (
      <>
        <td>{rowData.university}</td>
        <td>{rowData.codes}</td>
        <td>{rowData.email}</td>
        <td>
          {
            <Chip
              label={
                rowData.status === '1'
                  ? 'Unused'
                  : rowData.status === '0'
                  ? 'Active'
                  : 'Not Active'
              }
              color={getStatusColor(rowData.status)}
              sx={{ marginBottom: 1 }}
            />
          }
        </td>
      </>
    );
  };

  const handleRequestChangePage = (event, newPage) => {
    setRequestPage(newPage);
  };

  const handleRequestChangeRowsPerPage = event => {
    setRequestRowsPerPage(parseInt(event.target.value, 10));
    setRequestPage(0);
  };

  // Function to get dynamic status color
  const getStatusColor = status => {
    if (status === '1') return 'warning'; // Unused (Orange)
    if (status === '0') return 'success'; // Active (Green)
    return 'error'; // Not active (Red)
  };

  return (
    <>
      {loading ? (
        <Loading.orgLoader />
      ) : (
        <section className="OrgStudent-section">
          <div className="container">
            <div className=" row mb-3">
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2">
                <b className="fs-4 ">All Students ({totalStudents})</b>
              </div>
              {/* <div className="col-lg-2 col-md-2 col-sm-12 col-2"></div> */}
              <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                <TableFilter
                  setCategory={setCategory}
                  setQuery={setQuery}
                  categories={categories}
                />
              </div>
            </div>
            <div className="shadow-box">
              <div className="table-top table-sec">
                <Table
                  columns={requestStatusColumns}
                  rows={filteredData}
                  page={requestPage}
                  rowsPerPage={requestRowsPerPage}
                  handleChangePage={handleRequestChangePage}
                  handleChangeRowsPerPage={handleRequestChangeRowsPerPage}
                  renderRow={renderRequestStatusRow}
                  loading={loading}
                  role="organization"
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ViewAllStudents;
